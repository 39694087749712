import { PortfolioParams } from 'app/models/portfolio-params'

export const getPortfolios = (state: {
  portfolios: { portfolios: PortfolioParams[] }
}) => {
  return state.portfolios.portfolios
}

export const getPortfolio = (state: {
  portfolios: { portfolio: PortfolioParams }
}) => {
  return state.portfolios.portfolio
}

export const getPortfolioLoader = (state: {
  portfolios: { isLoading: boolean }
}) => {
  return state.portfolios.isLoading
}