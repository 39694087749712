import * as types from './types'
import { createReducer } from 'app/state/utils'

export interface SubTradeState {
  subTrades: any[]
  subTrade?: any
  lockedSubTrades: any[]
  isLoading: boolean
}

const initialState: SubTradeState = {
  subTrades: [],
  subTrade: undefined,
  lockedSubTrades: [],
  isLoading: false,
}

const subTradesReducer = createReducer(initialState)({
  [types.GET_SUB_TRADES]: (state: any, payload: any) => {
    return { ...state, subTrades: payload.payload }
  },
  [types.GET_SUB_TRADE]: (state: any, payload: any) => {
    return { ...state, subTrade: payload.payload }
  },
  [types.ADD_SUB_TRADES]: (state: any, payload: any) => {
    return {
      ...state,
      subTrades: [{ ...payload.payload }, ...state.subTrades],
    }
  },
  [types.EDIT_SUB_TRADES]: (state: any, payload: any) => {
    const index = state.subTrades.findIndex(
      (subTrade: { id: any }) => subTrade.id === payload.payload.id
    )
    const newArray = [...state.subTrades]
    newArray[index] = { ...payload.payload }
    if (state?.subTrade?.id === payload?.payload?.id) {
      return {
        ...state,
        subTrades: newArray,
        subTrade: { ...payload.payload },
      }
    }
    return {
      ...state,
      subTrades: newArray,
    }
  },
  [types.GET_LOCKED_SUB_TRADES]: (state: any, payload: any) => {
    return { ...state, lockedSubTrades: payload.payload }
  },
  [types.DELETE_SUB_TRADE]: (state: any, payload: any) => {
    if (!payload.payload || payload.payload.length === 0) {
      return state
    }
    const deletedIds = payload.payload
      .filter((payload: any) => !payload.error)
      .map((payload: any) => payload.id)
    return {
      ...state,
      subTrades: state.subTrades.filter((subTrade: any) => !deletedIds.includes(subTrade.id)),
      subTrade:
        state.subTrade && deletedIds.includes(state.subTrade.id) ? null : state.subTrade,
    }
  },
  [types.START_LOADING_SUB_TRADES]: (state: SubTradeState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_SUB_TRADES]: (state: SubTradeState) => {
    return { ...state, isLoading: false }
  },
})

export default subTradesReducer
