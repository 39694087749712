import {
  Anchor,
  Breadcrumbs,
  Group,
  Stack,
} from '@mantine/core'
import TextInput from 'app/views/components/inputs/TextInput'
import IonIcon from '@reacticons/ionicons'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { DealParams } from 'app/models/deal-params'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { getDealAmendments } from 'app/state/ducks/deal-amendments/selectors'
import { loadAllDealAmendments } from 'app/state/ducks/deal-amendments/thunks'
import { loadAllDeals, setOneDeal } from 'app/state/ducks/deals/thunks'
import DealHeader from '../deals/deal-details/deal-header'
import DealDetailSidebar from '../deals/deal-details/deal-detail-sidebar'
import SegmentControl from 'app/views/components/segments/segment-control'
import { formatNumberToCurrency, getLabelByValue } from 'app/utils/util-functions'
import { dealFeeTypeOptions, frequencyOptions } from 'app/models/dropdown-options'


export default function DealFeesContentPage(): JSX.Element {
  const navigate = useNavigate()
  const { dealId, status, feeType } = useParams()

  const dispatch = useDispatch()
  const deal: DealParams | undefined = useSelector(getDeal)
  const allDeals: DealParams[] = useSelector(getDeals)
  const dealAmendments: DealParams[] = useSelector(getDealAmendments)
  const fee = deal?.fees.find(fee => fee.feeType === feeType)

  const tabs: { [key: string]: () => string } = {};
  deal?.fees.forEach(fee => {
    tabs[fee.feeType] = () => `/dealmanagement/deal/${dealId}/${status}/fees/${fee.feeType}`;
  });

  const activeTabs = {
    tab: tabs[feeType ?? ''],
    locked: '',
  }

  useEffect(() => {
    if (allDeals) {
      const selectedDeal = allDeals.find(deal => deal.id === dealId)
      if (selectedDeal) {
        selectedDeal.amendment = false
        dispatch(setOneDeal(selectedDeal))
      }
    }
    if (dealAmendments) {
      const amended_deal = dealAmendments.find(
        amendment => amendment.id === dealId
      )
      if (amended_deal) {
        amended_deal.amendment = true
        dispatch(setOneDeal(amended_deal))
      }
    }
  }, [allDeals, dealId, dealAmendments])

  useEffect(() => {
    dispatch(loadAllDeals())
    dispatch(loadEntities())
  }, [dealId])

  useEffect(() => {
    dispatch(loadAllFacilities())
    dispatch(loadAllDealAmendments())
  }, [])

  const items = [
    { title: 'Deal Management', href: '/dealmanagement' },
    { title: 'Deal', href: "/dealmanagement/deal/" + dealId + "/" + status },
  ].map((item, index) => (
    <Anchor
      onClick={() => navigate(item.href)}
      key={'deal-page-breadcrum' + index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  return (
    <Stack>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      {deal ? <DealHeader deal={deal} showStatusPill={false} /> : null}
      <Group h="100%">
        <SegmentControl tabs={tabs} activeTabs={activeTabs} lockedTabs={{}} />
      </Group>

      <Group noWrap position="apart" align="flex-start" h="100vh">
        <Stack className="data-header-part">
          <Stack w="100%">
            <Group w="100%" noWrap>
              <TextInput
                w="100%"
                readOnly
                label="Fee Type"
                value={getLabelByValue(fee?.feeType ?? '', dealFeeTypeOptions)}
              />
              <TextInput
                w="100%"
                readOnly
                label="Fee Rate"
                value={fee?.feeRate}
              />
              <TextInput
                w="100%"
                readOnly
                label="Fee Rate"
                value={formatNumberToCurrency((Number(fee?.feeRate) / 100) * Number(deal?.amount), deal?.currency ?? 'USD')}
              />
              <TextInput
                w="100%"
                readOnly
                label="Payment Date"
                value={fee?.firstPaymentDate}
              />
              <TextInput
                w="100%"
                readOnly
                label="Frequency"
                value={getLabelByValue(fee?.frequency ?? '', frequencyOptions)}
              />
            </Group>
          </Stack>
        </Stack>

        {deal ? <DealDetailSidebar deal={deal} /> : null}
      </Group>
    </Stack>
  )
}
