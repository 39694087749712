import { LoanParams } from 'app/models/loan-params'
import { ServicingPrincipalPaymentTransactionType } from 'app/models/dropdown-options'
import moment from 'moment'
import { EntityParams } from 'app/models/entity-params'
import { Option } from 'app/models/dropdown-options'
import { currencyOptions } from 'app/models/dropdown-options';

export function isNumeric(str: string) {
  return /^[0-9]+$/.test(str)
}

export function isAlphanumeric(str: string) {
  return /^[a-zA-Z0-9]+$/.test(str)
}

export function isValidEmail(email: string) {
  if (typeof email != 'string') return false // we only process strings!
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

export const numberOfDaysBetweenTwoDates = (startDate: any, endDate: any) => {
  const Difference_In_Time = endDate.getTime() - startDate.getTime()
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
  return Difference_In_Days
}

export const getDayBasis = (dayBasis: string) => {
  return Number(nextStringByDelimiter('_', dayBasis))
}

export const countDecimals = (value: number) => {
  const text = value.toString()
  // verify if number 0.000005 is represented as "5e-6"
  if (text.indexOf('e-') > -1) {
    const [, trail] = text.split('e-')
    const deg = parseInt(trail, 10)
    return deg
  }
  // count decimals for number in representation like "0.123456"
  if (Math.floor(value) !== value) {
    return value.toString().split('.')[1].length || 0
  }
  return 0
}

export function nextWordMatch(_preceedingWord: string, _text: string) {
  const words = _text.split(' ')
  const wordIndex = words.findIndex(word => word == _preceedingWord)
  return words[wordIndex + 1]
}

export const nextStringByDelimiter = (delimiter: string, _text: string) => {
  const words = _text.split(delimiter)
  return words[1]
}

export function roundTo(num: number, rounding: string) {
  switch (rounding) {
    case 'Five':
      return Number(num).toFixed(5)
    case 'NearestFourth':
      return roundToNearest(num, 0.25).toFixed(2)
    case 'UpwardFourth':
      return roundUpTo(Number(num), 0.25).toFixed(2)
    case 'DownwardFourth':
      return roundDownTo(Number(num), 0.25).toFixed(2)
    case 'NearestEighth':
      return roundToNearest(Number(num), 0.125).toFixed(3)
    case 'UpwardEighth':
      return roundUpTo(Number(num), 0.125).toFixed(3)
    case 'DownwardEighth':
      return roundDownTo(Number(num), 0.125).toFixed(3)
    case 'NearestSixteenth':
      return roundToNearest(Number(num), 0.0625).toFixed(4)
    case 'UpwardSixteenth':
      return roundUpTo(Number(num), 0.0625).toFixed(4)
    case 'DownwardSixteeth':
      return roundDownTo(Number(num), 0.0625).toFixed(4)
    case 'NearestTenth':
      return roundToNearest(Number(num), 0.1).toFixed(1)
    case 'UpwardTenth':
      return roundUpTo(Number(num), 0.1).toFixed(1)
    case 'DownwardTenth':
      return roundDownTo(Number(num), 0.1).toFixed(1)
    case 'NearestHundredth':
      return roundToNearest(Number(num), 0.01).toFixed(2)
    case 'UpwardHundredth':
      return roundUpTo(Number(num), 0.01).toFixed(2)
    case 'DownwardHundredth':
      return roundDownTo(Number(num), 0.01).toFixed(2)
    case 'NoRounding':
      return Number(num)
  }
}

const roundToNearest = (number: number, _roundTo: number) => {
  return _roundTo * Math.round(number / _roundTo)
}

const roundUpTo = (number: number, _roundTo: number) => {
  return Math.ceil(number / _roundTo) * _roundTo
}

const roundDownTo = (number: number, _roundTo: number) => {
  return Math.floor(number / _roundTo) * _roundTo
}

export function decimalPlaces(num: any) {
  const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
  if (!match) {
    return 0
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0),
  )
}

export function addMonths(dt: Date, n: number) {
  return new Date(dt.setMonth(dt.getMonth() + n))
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatDateToUTC(date: Date) {
  return moment(date).format('YYYY-MM-DD')
}

export function formatDateToMDY(date: Date) {
  return moment(date).format('MM-DD-YYYY')
}

export function stringToDate(date: string): Date {
  if (!date || date === '' || isNaN(new Date(date + 'T00:00:00').getTime())) {
    const newDate = new Date()
    newDate.setHours(0, 0, 0, 0)
    return newDate
  }
  return new Date(date + 'T00:00:00')
}

export const calculateInterestPaymentScheduler = (
  facilityFrequency: string | undefined | null,
  loan: LoanParams | undefined,
  bussinessDate: Date,
): { paymentDate: string; status: string }[] => {
  const periodMap: Record<string, number> = {
    Monthly: 1,
    Quarterly: 3,
    Semi: 6,
    Annual: 12,
  }

  const period: number = periodMap[facilityFrequency ?? 'Monthly'] ?? 1
  const resultCalculation: { paymentDate: string; status: string }[] = []
  let paymentDate: Date =
    new Date(loan?.nextInterestPaymentDate ?? '') ?? new Date()
  const loanEndDate: Date = new Date(loan?.endDate ?? '') ?? new Date()

  while (paymentDate <= loanEndDate) {
    resultCalculation.push({
      paymentDate: paymentDate.toDateString(),
      status: bussinessDate > paymentDate ? 'Past_Due' : 'Active',
    })
    paymentDate = addMonths(paymentDate, period)
  }
  resultCalculation.push({
    paymentDate: loanEndDate.toDateString(),
    status: 'Active',
  })

  return resultCalculation
}

export const calculatePikScheduler = (
  facilityFrequency: string | undefined | null,
  firstPikDate: string | Date | undefined,
  endDate: Date,
): { pikDate: string }[] => {
  const periodMap: Record<string, number> = {
    Monthly: 1,
    Quarterly: 3,
    Semi: 6,
    Annual: 12,
  }

  const period: number = periodMap[facilityFrequency ?? 'Monthly'] ?? 1
  const resultCalculation: { pikDate: string }[] = []
  let pikDate: Date = new Date(firstPikDate ?? '') ?? new Date()

  while (pikDate < endDate) {
    resultCalculation.push({
      pikDate: pikDate.toDateString(),
    })
    pikDate = addMonths(pikDate, period)
  }

  return resultCalculation
}

export const formatNumberToCurrency = (
  value: number, // The numeric value to be formatted as currency.
  currencyCode: string, // The currency code for the desired currency format (i.e. 'USD', 'EUR').
  maximumFractionDigits?: number, // optional - The maximum number of fraction digits to use.
  minimumFractionDigits?: number, // optional - The minimum number of fraction digits to use.
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact', // optional - The notation to use for formatting the number.
): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
    notation: notation,
  }).format(value)
}

export const formatToIsoCurrencyFormat = (
  value: number,
  currencyCode: string, 
  maximumFractionDigits?: number, 
  minimumFractionDigits?: number, 
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact', 
): string => {
const formattedNumber = new Intl.NumberFormat('en-US', {
  style: 'decimal', 
  maximumFractionDigits: maximumFractionDigits,
  minimumFractionDigits: minimumFractionDigits,
  notation: notation,
}).format(value);
return `${currencyCode} ${formattedNumber}`;
}


export function camelCaseToHumanReadable(input: string): string {
  // Add a space before each uppercase letter, then capitalize the first letter of each word
  return input
    .replace(/([A-Z])/g, ' $1') // Add space before each uppercase letter
    .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter of the first word
    .split(' ') // Split the string into words
    .join(' ') // Join the words with a space
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function cleanErrorMessage(message:string) {
  return message.replace(/'(\w+)'/g, (_, fieldName) => {
    const readableFieldName = fieldName
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/([a-zA-Z])(\d)/g, "$1 $2"); // Add space between letters and numbers
    return `'${readableFieldName}'`;
  });
}


export function getTransactionLabel(
  transactionValue: string,
  isInterestPaid: boolean,
): string {
  const updatedTransactionValue =
    transactionValue === 'InterestPayment'
      ? transactionValue
      : transactionValue + (isInterestPaid ? 'WithInterest' : '')

  const transaction = ServicingPrincipalPaymentTransactionType.find(
    item => item.value === updatedTransactionValue,
  )

  return transaction ? transaction.label : 'Unknown Payment Type'
}

// Create a map from entity IDs to entity names
export function mapEntityIdsToNames(entities: EntityParams[]) {
  const entityIdToName = new Map<string, string>()
  if (Array.isArray(entities)) {
    for (const entity of entities) {
      if (entity.id) {
        entityIdToName.set(entity.id, entity.entityName)
      }
    }
  }
  return entityIdToName
}

export function getLabelByValue(value: string, options: Option[]) {
  const option = options.find(opt => opt.value === value)
  return option ? option.label : ''
}

export const formatDateSnapshot = () => {
  const now = new Date();
  const dayOfWeek = now.toLocaleDateString('en-US', { weekday: 'long' }); // E.g., "Friday"
  const monthDayYear = now.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }) + ', ' + now.getFullYear(); // E.g., "July 23, 2023"
  
  return { dayOfWeek, monthDayYear };
};

export function getCurrencyDescription(currencyCode: string): string | undefined {
  const currency = currencyOptions.find(({ value }) => value === currencyCode);
  return currency?.label.split('-')[0].trim();
}

export function getDirection(amount: number): 'up' | 'down' | 'none' {
  if (amount > 0) return 'up';
  if (amount < 0) return 'down';
  return 'none';
}

export const sortData = <T>(
  data: T[],
  sortBy: keyof T | string,
  order: 'Ascending' | 'Descending' = 'Ascending'
): T[] => {
  return [...data].sort((a:any, b) => {
    if (!Object.keys(a).includes(sortBy as string)) {
      return 0; // Ignore invalid sortBy fields
    }

    const aValue = a[sortBy as keyof T];
    const bValue = b[sortBy as keyof T];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      const comparison = aValue.localeCompare(bValue);
      return order === 'Ascending' ? comparison : -comparison;
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'Ascending' ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });
};

export function filterByTradeDate(value: [Date, Date] | [], tradeDate: string | Date): boolean {
  if (Array.isArray(value) && value.length === 2) {
    const [startDate, endDate] = value;

    // Ensure tradeDate is formatted consistently with the range dates
    const formattedTradeDate = moment(tradeDate);

    // Check if tradeDate is within the range
    return (
      formattedTradeDate.isSameOrAfter(moment(startDate), 'day') &&
      formattedTradeDate.isSameOrBefore(moment(endDate), 'day')
    );
  }
  return true; // If the value is not a valid range, don't filter
}


export function toTitleCase(str: string) {
  return str
      .split(/(?=[A-Z])|_/)
      .join(' ')
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function exportToCSV<T extends Record<string, any>>(data: T[], fileName: string): void {
  if (!data.length) {
    return;
  }

  // Extract headers from object keys, filter out unwanted columns
  const headers = Object.keys(data[0]).filter((key) => key !== 'agencyAdmin' && key !== 'observers');

  // Map rows by joining values in CSV format
  const rows = data.map((item) =>
      headers.map((header) => `"${(item[header] ?? '').toString().replace(/"/g, '""')}"`).join(',')
  );

  // Create CSV content
  const csvContent = [headers.join(','), ...rows].join('\n');

  // Create and download the CSV file
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  // Cleanup
  URL.revokeObjectURL(url);
}
