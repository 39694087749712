import * as types from './types'

export const getSubTrades = (payload: any) => ({
  type: types.GET_SUB_TRADES,
  payload,
})

export const getSubTrade = (payload: any) => ({
  type: types.GET_SUB_TRADE,
  payload,
})

export const addSubTrade = (payload: any) => ({
  type: types.ADD_SUB_TRADES,
  payload,
})

export const editSubTrade = (payload: any) => ({
  type: types.EDIT_SUB_TRADES,
  payload,
})

export const deleteSubTrade = (payload: any) => ({
  type: types.DELETE_SUB_TRADE,
  payload,
})

export const getLockedSubTrades = (payload: any) => ({
  type: types.GET_LOCKED_SUB_TRADES,
  payload,
})

export const startLoadingSubTrades = () => ({
  type: types.START_LOADING_SUB_TRADES,
  payload: null,
})

export const stopLoadingSubTrades = () => ({
  type: types.STOP_LOADING_SUB_TRADES,
  payload: null,
})

