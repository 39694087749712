import React from 'react';
import { Text } from '@mantine/core';

interface ItemNameProps {
    name: string;
}

const ItemName: React.FC<ItemNameProps> = ({ name }) => (
    <Text className="entity-name">
        {name}
    </Text>
);

export default ItemName;