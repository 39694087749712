import IonIcon from '@reacticons/ionicons'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import {
    Anchor,
    Breadcrumbs,
    Group,
    Stack,
    Modal,
    Text,
} from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import { ContractType } from 'app/models/common-types'
import { Fees } from 'app/models/fees-params'
import { getFacilityFees, getFeeScheduler } from 'app/state/ducks/fees/selectors'
import { dayBasisOptions, frequencyOptions } from 'app/models/dropdown-options'
import { calculateFeeScheduler, loadFeesByFacility } from 'app/state/ducks/fees/thunks'
import { loadOneFacility, setOneFacility } from 'app/state/ducks/facilities/thunks'
import { UpdatedFacilitiesScreen } from 'app/views/pages/deals-management/facilities/updated-facilities-screen'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { loadSchedulerAmortizationByFacility } from 'app/state/ducks/amortization/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import SegmentControlUpdated from '../segments/segment-control-updated'
import PrimaryInput from '../buttons/PrimaryInput'
import FacilityHeader from 'app/views/pages/deals-management/facilities/facility-details/facility-header'
import NotesAndAttachment from '../notes-and-attachemnt/notes-and-attachment-full'
import MainTable from '../Table/MainTable'
import { colsFacilityFees } from './facility-fees-columnDefs'
import { NewTextInput } from '../inputs/NewTextInput'


export default function FeesPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { facilityId, status, feeType } = useParams()
    const facility = useSelector(getFacility)
    const facilityAmendments = useSelector(getFacilityAmendments)
    const feeScheduler = useSelector(getFeeScheduler)
    const facilityFees: Fees[] = useSelector(getFacilityFees)
    const [openEdit, setOpenEdit] = useState<boolean>(false)
    const deals = useSelector(getDeals)
    const [deal, setDeal] = useState<DealParams>()
    const [fees, setFees] = useState<Fees[]>([]);
    const fee = fees.find(fee => fee.feeType === feeType)
    const [isLoading, setIsLoading] = useState(true)

    const tabs: { [key: string]: { route: string; label: string; active: boolean; pageRender: JSX.Element; } } = {};
    fees.forEach(fee => {
        tabs[fee.feeType + 'Fee'] = { route: `/dealmanagement/facilities/${facilityId}/${status}/fees/${fee.feeType}`, label: fee.feeType.toUpperCase() + ' FEE', active: fee.feeType === feeType ? true : false, pageRender: <FeesPage /> };
    });

    useEffect(() => {
        dispatch(loadEntities())
        dispatch(loadContacts())
        dispatch(loadWireInstructions())
        dispatch(loadAllDeals())
        dispatch(loadAllFacilityAmendments())
    }, [])

    useEffect(() => {
        if (facilityAmendments && facilityAmendments.length > 0) {
            const amendment = facilityAmendments.find(
                (amendment: any) => amendment.id === facilityId
            )
            if (amendment) {
                dispatch(setOneFacility(amendment))
            }
        }
    }, [facilityAmendments, facilityId])

    useEffect(() => {
        if (facility &&
            facility.amendmentFees &&
            facility.amendmentFees.length > 0) {
            setFees(facility.amendmentFees);
        } else {
            setFees(facilityFees);
        }
    }, [facilityFees, facility])

    useEffect(() => {
        if (!facilityId) {
            return
        }
        dispatch(loadFeesByFacility(facilityId))
        dispatch(loadSchedulerAmortizationByFacility(facilityId))
        dispatch(loadOneFacility(facilityId, status ?? ''))

    }, [facilityId, status])

    useEffect(() => {
        if (!fee?.id) {
            return
        }
        const loadData = async (feeId: string) => {
            await Promise.all([
                dispatch(loadNoteByContract(feeId, ContractType.Fees)),
                dispatch(calculateFeeScheduler(feeId, facilityId ?? ''))
            ])
            setIsLoading(false)
        }
        loadData(fee.id)

    }, [fee])


    useEffect(() => {
        if (!facility || !deals) {
            return
        }
        const selectedDeal = deals.find(
            (deal: DealParams) => deal.id === facility.dealId.id
        )
        if (!selectedDeal) {
            return
        }
        setDeal(selectedDeal)
    }, [facility, deals])

    const items = [
        { title: 'Deal Management', action: () => navigate('/dealmanagement') },
        {
            title: 'Facilities',
            action: () => navigate('/dealmanagement/facilities'),
        },
        {
            title: facility?.name ?? '',
            action: () =>
                navigate(
                    `/dealmanagement/facilities/${facility?.id}/${facility?.status}`
                ),
        },
    ].map((item, index) => (
        <Anchor
            onClick={() => item.action()}
            key={index}
            color="#374151"
            fw={500}
            fz="14px"
        >
            {item.title}
        </Anchor>
    ))


    const feesRowData = feeScheduler?.map(({ amount, date }, index) => {
        const previousPaymentDate = index === 0 ? fee?.previousPaymentDate ?? facility?.startDate : feeScheduler[index - 1].date
        return (
            { startDate: previousPaymentDate, endDate: date, amount: formatNumberToCurrency((Number(amount) ?? 0), (facility?.baseCurrency ?? 'USD')), paidDate: '', status: 'Active' }
        )
    })

    return (
        <PageLayout>
            <Stack w="100%">
                <Breadcrumbs
                    separator={<IonIcon name="chevron-forward-outline" />}
                >
                    {items}
                </Breadcrumbs>
                {facility ? <FacilityHeader facility={facility} customTitle='Fees' showStatusPill={false} /> : null}
                <Group w="100%" position="apart" className='tabsGapsActivity'>
                    <Group position="right">
                        <SegmentControlUpdated tabs={tabs} />
                    </Group>
                    <Group noWrap>
                        {facility?.status === 'Draft' && (
                            <PrimaryInput text="Edit Fees" onClick={() => setOpenEdit(true)} />
                        )}
                    </Group>
                </Group>
                <Text className='sectionDetailHeader'>Fee Details</Text>
                <Group position='apart' mt='12px' mb='12px'>
                    <Group noWrap className='dataEntrySubSection'>
                        <NewTextInput readOnly label='Fee Rate' value={fee?.feeRate} />
                    </Group>
                    <Group noWrap className='dataEntrySubSection'>
                        <NewTextInput readOnly label='Day Basis' value={dayBasisOptions.find(
                            option => option.value === fee?.dayBasis
                        )?.label ?? ''} />
                    </Group>
                    <Group noWrap className='dataEntrySubSection'>
                        <NewTextInput readOnly label='Frequency' value={
                            frequencyOptions.find(
                                option => option.value === fee?.frequency
                            )?.label ?? ''
                        } />
                    </Group>
                    <Group noWrap className='dataEntrySubSection'>
                        <NewTextInput readOnly label='First Payment Date' value={fee?.firstPaymentDate} />
                    </Group>
                </Group>
                <Text className='sectionDetailHeader'>Schedule</Text>
                <Group w="100%" noWrap mt='12px'>
                    <MainTable
                        tableName='Facility Fees Table'
                        rowsPerPage={5}
                        enableColumnResizing={true}
                        withPagination={true}
                        csvExportEnabled={true}
                        enableTopToolbar={false}
                        headerBackgroundColor='#F0EEEE'
                        overflow="auto"
                        minHeight="10px"
                        columnDefs={colsFacilityFees}
                        data={feesRowData ?? []}
                        isLoading={isLoading}
                        setRow={() => { }}
                    />
                </Group>
                <Group w="100%" noWrap mt='30px'>
                    <NotesAndAttachment
                        itemId={facility?.id ?? ''}
                        type={ContractType.Facilities}
                        itemAdmin={facility?.accountManagementAdmin ?? ''}
                        notesId={fee?.id ?? ''}
                        notesAdmin={fee?.agencyAdmin ?? ''}
                        notesType={ContractType.Fees}
                    />
                </Group>
                {facility?.status === 'Draft' && (
                    <Modal
                        className="modal-body modal-body-template fit-content-modal"
                        opened={openEdit}
                        onClose={() => setOpenEdit(false)}
                        centered={true}
                        size="77%"
                    >
                        <UpdatedFacilitiesScreen
                            deal={deal}
                            amendment={facility.amendmentType ? 'amend' : null}
                            facility={facility}
                            onClose={() => setOpenEdit(false)}
                            step={4}
                        />
                    </Modal>
                )}
            </Stack>
        </PageLayout>
    )
}
