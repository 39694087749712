import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { TradeCreateDrillPage } from './trade-create-drill-page'


export function TradeCreateLandingPage(tab: { tab: string }) {
  return (
    <PageLayout>
      <PageTitle pageTitle='Trade Entry' />
      <TradeCreateDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
