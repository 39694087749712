import React, { useState } from 'react'
import { Paper, Group, Badge, Button } from '@mantine/core'
import ItemName from '../../../common/item-name'
import { EntityTypeParams } from 'app/models/entity-type-params'
import { useDispatch } from 'react-redux'
import { removeEntityTypes } from 'app/state/ducks/entity-type/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation'

interface EntityItemProps {
    entity: EntityTypeParams
    isExpanded: boolean
    onEdit: (item: EntityTypeParams) => void
}

const EntityItem: React.FC<EntityItemProps> = ({
    entity,
    isExpanded,
    onEdit,
}) => {
    const dispatch = useDispatch()
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false) // State for delete modal

    const handleDeleteEntity = async (entityId: string) => {
        const result: any = await dispatch(removeEntityTypes(entityId))
        if (!result.success) {
            ErrorNotification({ message: result.payload, title: 'Delete Entity Type Failed' })
            return
        }
        SuccessNotification({
            message: 'The entity type has been successfully deleted.',
            title: 'Delete Successful',
        })
        setShowDeleteModal(false) // Close the modal
    }

    const confirmDelete = () => {
        handleDeleteEntity(entity.id ?? '') // Perform the delete action
    }

    return (
        <>
            <Paper
                p="md"
                radius="md"
                className={`paper ${isExpanded ? 'paper-expanded' : ''}`}
            >
                <Group position="apart">
                    <Group spacing="sm">
                        <Badge className={`status-badge ${entity.isActive ? 'active' : 'inactive'}`}>
                            {entity.isActive ? 'ACTIVE' : 'INACTIVE'}
                        </Badge>
                        <ItemName name={entity.name} />
                    </Group>
                    <Group spacing="xs">
                        <Group spacing="xs">
                            <Button onClick={() => onEdit(entity)} className="edit-button">
                                Edit
                            </Button>
                            <Button
                                onClick={() => setShowDeleteModal(true)} // Show confirmation modal
                                className="delete-button delete-button-pink"
                            >
                                Delete
                            </Button>
                        </Group>
                    </Group>
                </Group>
            </Paper>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <DeleteConfirmation
                    onConfirm={confirmDelete} // Call the delete handler
                    onCancel={() => setShowDeleteModal(false)} // Close modal on cancel
                    message="Delete This Entity Type?" // Custom message
                />
            )}
        </>
    )
}

export default EntityItem
