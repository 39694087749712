import {
  Group,
  Stack,
  Title,
} from '@mantine/core'


export default function LenderAllocationsHeader(): JSX.Element {
  return (
    <Stack className="detailsHeader">
      <Group noWrap>
        <Title className='detailsTitle'>Lender Allocations</Title>
      </Group>
    </Stack>
  )
}
