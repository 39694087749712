import { Anchor, Avatar, Text, Group, Modal, Stack, Tooltip } from '@mantine/core'
import { useEffect, useState } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import { useDispatch, useSelector } from 'react-redux'
import {
    loadOneFacility,
    setOneFacility,
} from 'app/state/ducks/facilities/thunks'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import { useNavigate, useParams } from 'react-router-dom'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import getInitials from 'app/views/components/functions/getInitials'
import { EntityParams } from 'app/models/entity-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadLendersPositionsByFacility } from 'app/state/ducks/lenders-positions/thunks'
import { getFacilityLendersPositionsWithLoading } from 'app/state/ducks/lenders-positions/selectors'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { UpdatedFacilitiesScreen } from '../updated-facilities-screen'
import { DealParams } from 'app/models/deal-params'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { loadFeesByFacility } from 'app/state/ducks/fees/thunks'
import { Fees } from 'app/models/fees-params'
import { getFacilityFees } from 'app/state/ducks/fees/selectors'
import { loadSchedulerAmortizationByFacility } from 'app/state/ducks/amortization/thunks'
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import { DetailSection } from 'app/views/components/detailSections/detail-section'


export default function FacilityDetailsInfo() {
    const { facilityId, status } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const facility = useSelector(getFacility)
    const currentDeal = useSelector(getDeal)
    const entityIdToName = new Map<string, string>()
    const entities: EntityParams[] = useSelector(getEntities)
    const { data, isLoading } = useSelector(
        getFacilityLendersPositionsWithLoading
    )
    let lendersPositions = data
    const deals: DealParams[] = useSelector(getDeals)
    const [deal, setDeal] = useState<DealParams | undefined>()
    const facilityAmendments: FacilityParams[] = useSelector(
        getFacilityAmendments
    )
    const allIndexes = useSelector(getIndexRateOptions)
    const [facilityAmendment, setFacilityAmendment] =
        useState<FacilityParams | null>(null)
    const [amendment, setAmendment] = useState<string | null>(null)
    const fees: Fees[] = useSelector(getFacilityFees)
    // const amortizationScheduler: AmortizationScheduleParams[] = useSelector(getFacilitySchedulerAmortization)
    const [facilityFees, setFacilityFees] = useState<Fees[]>(fees);

    const currentAmendmentFee = facilityAmendment?.amendmentFees
    const oldAmendmentFee = facility?.amendmentFees

    if (
        facility &&
        facility.amendmentLenders &&
        facility.amendmentLenders.length > 0
    ) {
        lendersPositions = facility.amendmentLenders
    }

    useEffect(() => {
        if (fees.length > 0 && !oldAmendmentFee && !currentAmendmentFee) {
            setFacilityFees(fees);
        } else if (fees && oldAmendmentFee && currentAmendmentFee && oldAmendmentFee.length > 0 && currentAmendmentFee.length > 0) {
            setFacilityFees(oldAmendmentFee);
        } else if (fees && oldAmendmentFee && currentAmendmentFee && oldAmendmentFee.length > 0 && currentAmendmentFee.length < 1) {
            setFacilityFees(currentAmendmentFee);
        } else if (fees && !oldAmendmentFee && currentAmendmentFee) {
            setFacilityFees(currentAmendmentFee)
        } else {
            setFacilityFees([])
        }
    }, [fees, oldAmendmentFee, currentAmendmentFee, facility])

    const [openEdit, setOpenEdit] = useState(false)

    useEffect(() => {
        if (!facilityId) {
            return
        }
        dispatch(loadOneFacility(facilityId, status ?? 'Draft'))
        dispatch(loadEntities())
        dispatch(loadAllFacilityAmendments())
        dispatch(loadLendersPositionsByFacility(facilityId))
        dispatch(loadFeesByFacility(facilityId))
        dispatch(loadSchedulerAmortizationByFacility(facilityId))
        dispatch(loadIndexRateOptions())
    }, [facilityId])
    useEffect(() => {
        if (!facility || !deals) {
            return
        }
        const deal = deals.find(d => d.id == facility.dealId.id)
        if (deal) {
            setDeal(deal)
        }
    }, [deals, facility])

    useEffect(() => {
        if (!currentDeal) {
            return
        }
        setDeal(currentDeal)
    }, [currentDeal])

    useEffect(() => {
        if (!facilityAmendments || !facilityId) {
            return
        }
        const amended_facility = facilityAmendments.find(
            amendment => amendment.id === facilityId
        )
        if (amended_facility) {
            setFacilityAmendment(amended_facility ?? null)
            dispatch(setOneFacility(amended_facility))
            setAmendment('amend')
        }
    }, [facilityId, facilityAmendments, facilityFees])

    if (Array.isArray(entities)) {
        for (const entity of entities) {
            if (entity.id) {
                entityIdToName.set(cleanUUID(entity.id), entity.entityName)
            }
        }
    }

    const facilityDetailsObjectArray = [
        {
            label: 'Facility Type',
            value: facility?.facilityType,
        },
        {
            label: 'Amount',
            value: formatNumberToCurrency(Number(facility?.amount) ?? 0, facility?.baseCurrency ?? 'USD'),
        }, {
            label: 'Utilized Amount',
            value: formatNumberToCurrency(Number(facility?.utilizedAmount) ?? 0, facility?.baseCurrency ?? 'USD'),
        },
        {
            label: 'Start Date',
            value: facility?.startDate,
        },
        {
            label: 'Maturity Date',
            value: facility?.maturity,
        },
        {
            label: 'Facility ID',
            value: facility?.id,
        },
        {
            label: 'Facility CUSIP',
            value: facility?.cusip,
        },
        {
            label: 'Facility ISIN',
            value: facility?.isin,
        },
        {
            label: 'Facility LXID',
            value: facility?.lxId,
        },
        {
            label: 'Facility BBG ID',
            value: facility?.bbgId
        },
        {
            label: <Text className='sectionItemTitleClickable' onClick={() =>
                navigate(
                    `/dealmanagement/facilities/${facility?.id}/borrowers`,
                    { state: { facility, entities } }
                )
            }>Borrowers</Text>,
            value: (
                <Group>
                    <Tooltip.Group openDelay={300} closeDelay={100}>
                        <Avatar.Group
                            spacing="sm"
                            onClick={() =>
                                navigate(
                                    `/dealmanagement/facilities/${facility?.id}/borrowers`,
                                    { state: { facility, entities } }
                                )
                            }
                        >
                            {facility?.borrowers?.map((borrower, i) => {
                                const entityName = entityIdToName.get(
                                    cleanUUID(borrower.id)
                                )
                                return (
                                    <Anchor key={'facility-borrower' + facilityId + i}>
                                        <Tooltip label={entityName} withArrow>
                                            <Avatar color="violet" radius="xl">
                                                {getInitials(entityName)}
                                            </Avatar>
                                        </Tooltip>
                                    </Anchor>
                                )
                            })}
                        </Avatar.Group>
                    </Tooltip.Group>
                </Group>
            ),
        },
        {
            label: <Text className='sectionItemTitleClickable' onClick={() =>
                navigate(
                    `/dealmanagement/facilities/${facility?.id}/${facility?.status}/lenders`
                )
            }>Lender Allocations</Text>,
            value: isLoading ? <></> : (
                <Group
                    noWrap
                    onClick={() =>
                        navigate(
                            `/dealmanagement/facilities/${facility?.id}/${facility?.status}/lenders`
                        )
                    }
                >
                    <Tooltip.Group openDelay={300} closeDelay={100}>
                        <Avatar.Group spacing="sm">
                            {lendersPositions?.map((lender, i) => {
                                const lenderName =
                                    entities?.find(item => item.id === lender.lender.id)
                                        ?.entityName ?? ''
                                return (
                                    <Anchor
                                        key={'facility-accordion-lender-' + lender.id + i}
                                    >
                                        <Tooltip label={lenderName} withArrow>
                                            <Avatar size="md" color="teal" radius="xl">
                                                {getInitials(lenderName)}
                                            </Avatar>
                                        </Tooltip>
                                    </Anchor>
                                )
                            })}
                        </Avatar.Group>
                    </Tooltip.Group>
                </Group>
            ),
        },
        {
            label: 'Admin Agent',
            value: (
                <Tooltip
                    label={entityIdToName.get(cleanUUID(facility?.adminEntity?.id))}
                >
                    <Anchor>
                        <Avatar size="md" color="cyan" radius="xl">
                            {getInitials(
                                entityIdToName.get(cleanUUID(facility?.adminEntity?.id))
                            )}
                        </Avatar>
                    </Anchor>
                </Tooltip>
            ),
        },
        {
            label: 'Administrative Agent MEI',
            value: entities && deal
                ? entities.find(
                    entity =>
                        entity.id === cleanUUID(deal?.adminEntity?.id)
                )?.mei ?? ''
                : ''
        },
        {
            label: 'Interest Rate Option',
            value: facility?.iroValues.map(iro => allIndexes.find(index => index.id === iro.indexOption.id)?.indexOption).join(', '),
        },
        {
            label: 'Available Currencies',
            value: facility?.currencies.join(', '),
        },
        {
            label: 'Base Currency',
            value: facility?.baseCurrency,
        },
        {
            label: <Text className='sectionItemTitleClickable' onClick={() =>
                navigate(
                    `/dealmanagement/facilities/${facility?.id}/${facility?.status}/fees/${facilityFees[0]?.feeType}`
                )
            }>Fees</Text>,
            value: facilityFees.length.toString() ?? '0',
        },
        {
            label: <Text className='sectionItemTitleClickable' onClick={() =>
                navigate(
                    `/dealmanagement/facilities/${facility?.id}/${facility?.status}/amortization-schedule`
                )
            }>Amortization Schedule</Text>,
            value: facility?.amortizationScheduleId ? "Scheduled" : "Not Scheduled",
        },
        {
            label: 'Sub Limits',
            value: facility?.subLimits
                ?.map(sub => sub.subLimitsType)
                .join(', '),
        },
        { label: 'Classification', value: facility?.classification }
    ].concat(
        facility?.amendment
            ? [
                {
                    label: 'Amendment Date',
                    value: facility?.amendmentDate ?? '',
                },
            ]
            : []
    )

    return (
        <>
            <Modal
                className="modal-body modal-body-template fit-content-modal"
                opened={openEdit}
                onClose={() => setOpenEdit(false)}
                centered={true}
                size="77%"
            >
                {amendment ? (
                    <UpdatedFacilitiesScreen
                        deal={deal}
                        facility={facilityAmendment ?? facility}
                        onClose={() => setOpenEdit(false)}
                        amendment={amendment}
                        step={5}
                    />
                ) : (
                    <UpdatedFacilitiesScreen
                        deal={deal}
                        facility={facility}
                        onClose={() => setOpenEdit(false)}
                        step={5}
                    />
                )}
            </Modal>
            <Stack w='100%' className='sectionRemoveGap sectionBody'>
                <Stack w='100%' pt='12px' className='sectionDetailBody noHeader'>
                    <DetailSection detailObjArr={facilityDetailsObjectArray} columnCount={4} />
                </Stack>
            </Stack>
        </>
    )
}