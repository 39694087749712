import React from 'react';
import PrimaryInput from '../buttons/PrimaryInput';

type UploadCSVProps = {
  onParse: (data: Record<string, string>[]) => void; // Callback function to pass parsed data
};

const UploadCSV: React.FC<UploadCSVProps> = ({ onParse }) => {
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;
      const parsedData = parseCSV(text);
      onParse(parsedData); // Pass parsed data to parent via callback
    };
    reader.readAsText(file);
  };

  const parseCSV = (text: string): Record<string, string>[] => {
    const rows = text.split('\n').filter((row) => row.trim() !== ''); // Split by lines and remove empty rows
    const headers = rows[0].split(',').map((header) => header.trim()); // Extract headers and trim whitespace
    const data = rows.slice(1).map((row) => {
      const values = row.split(',').map((value) => value.trim());
      return headers.reduce<Record<string, string>>((obj, header, index) => {
        obj[header] = values[index] || ''; // Map headers to values
        return obj;
      }, {});
    });
    return data;
  };

  return (
    <div>
      <PrimaryInput
        onClick={() => document.getElementById('csvInput')?.click()}
        style={{ cursor: 'pointer', backgroundColor: '#32439A' }}
      >
        Upload CSV
      </PrimaryInput>
      <input
        type="file"
        id="csvInput"
        accept=".csv"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default UploadCSV;
