import { SubTradeState } from './reducer'

export const getSubTrades = (state: { subTrades: SubTradeState }) => state.subTrades.subTrades

export const getSubTrade = (state: { subTrades: SubTradeState }) => {
  return state.subTrades.subTrade
}

export const getLockedSubTrades = (state: { subTrades: SubTradeState }) => {
  return state.subTrades.lockedSubTrades
}

export const getSubTradeLoader = (state: { subTrades: SubTradeState }) => {
  return state.subTrades.isLoading
}

