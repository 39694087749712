import { StrategyParams } from 'app/models/strategy-params'

export const getStrategies = (state: {
  strategies: { strategies: StrategyParams[] }
}) => {
  return state.strategies.strategies
}

export const getStrategy = (state: {
    strategies: { strategy: StrategyParams }
}) => {
  return state.strategies.strategy
}

export const getStrategyLoader = (state: {
    strategies: { isLoading: boolean }
}) => {
  return state.strategies.isLoading
}