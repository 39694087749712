import {
  kycTypeOptions,
  countries,
  states,
  taxFormOptions,
  entityReferenceOptions,
  taxFormStatusOptions,
  institutionFinancialTypeOptions,
  kycStatusOptions,
  Option,
} from 'app/models/dropdown-options'
import TextInput from 'app/views/components/inputs/TextInput'
import { Group, MultiSelect, Select, Stack, Divider } from '@mantine/core'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { UseFormReturnType } from '@mantine/form'
import {
  EntityParams
} from 'app/models/entity-params'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import { stringToDate } from 'app/utils/util-functions'
import { getEntityProfiles } from 'app/state/ducks/entity-profile/selectors'
import { getEntityTypes } from 'app/state/ducks/entity-type/selectors'
import { EntityTypeHierarchy } from 'app/models/entity-type-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import UploadCSV from 'app/views/components/upload-csv/upload-csv'
import { transformCSVToSubmission } from './components/transformCSV'
import { submitBulkEntities } from 'app/state/ducks/entities/thunks'

type EntitiesFormProps = {
  entity?: EntityParams
  form: UseFormReturnType<any>
  isLoading: boolean;
  isStepper?: boolean;
  submitForm: any;
  transformValues: (values: Record<string, any>) => any;
  submitAndExit: () => void;
  handleBulkNotificationAndClosure: () => void;
}

export default function EntitiesFormPresenation({
  entity,
  form,
  isLoading,
  isStepper,
  submitForm,
  submitAndExit,
  transformValues,
  handleBulkNotificationAndClosure,
}: EntitiesFormProps) {
  const dispatch = useDispatch()

  const entityProfiles = useSelector(getEntityProfiles)
  const entityTypes = useSelector(getEntityTypes)
  const entities = useSelector(getEntities)

  const fundManagerEntityTypeIds = entityTypes.filter((type) => type.hierarchy === 'HierarchyFundManager').map((type) => type.id);
  const fundManagerEntities = entities.filter((entity) => fundManagerEntityTypeIds.includes(entity?.entityType?.id));

  const fundManagerEntityNames: Option[] = fundManagerEntities.map((entity) => ({
    value: entity.entityName,
    label: entity.entityName,
  }));

  const fundManagerEntityMeis: Option[] = fundManagerEntities.map((entity) => ({
    value: entity.mei ?? '',
    label: entity.mei?.toUpperCase() ?? '',
  }));

  const entityTypeOptions: Option[] = entityTypes.map(type => ({
    label: type.name,
    value: type.id ?? '',
  }))

  if (entity?.ukTreatyPassportExpiryDate)
    stringToDate(entity.ukTreatyPassportExpiryDate)

  function changeFundManagerOrMei(type: string, val: string) {
    if (type === 'fundManager') {
      const fundManager = fundManagerEntities.find(entity => entity.entityName === val)
      form.setFieldValue('fundManager', val)
      form.setFieldValue('fundManagerMei', fundManager?.mei ?? '')
    } else {
      const fundManager = fundManagerEntities.find(entity => entity.mei === val)
      form.setFieldValue('fundManagerMei', val)
      form.setFieldValue('fundManager', fundManager?.entityName ?? '')
    }
  }

  const handleEntityProfileChange = (
    selectedValue: string[],
    form: UseFormReturnType<
      any,
      (values: any) => any
    >
  ) => {
    form.setFieldValue('entityProfile', selectedValue)
    const pofiles = entityProfiles.filter(profile => selectedValue.includes(profile.id ?? ''))

    form.setFieldValue(
      'kycType',
      pofiles.some(profile => profile.kycType === 'Full')
        ? 'Full'
        : 'Lite'
    )
  }

  const handleCSVData = async (parsedData: Record<string, string>[]) => {
    const transformedEntities = transformCSVToSubmission(parsedData, entities, entityTypeOptions, entityProfiles, transformValues)
    const submittedEntities = await dispatch(submitBulkEntities(transformedEntities))
    console.log('submittedEntities', submittedEntities)
    handleBulkNotificationAndClosure()
  };


  return (
    <FormWrapper title={`${entity ? 'Edit' : 'Add a New'} Entity`}>
      <div className="content">
        <form onSubmit={submitForm}>
          <div className="create-new">
            <Stack w="100%">
              <Group noWrap w="100%" position='apart'>
                <span className="form-subtitle">
                  The following fields are required for entity creation
                </span>
                <UploadCSV onParse={handleCSVData} />
              </Group>


              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  withAsterisk
                  label="Entity Name"
                  name="entityName"
                  id="name"
                  placeholder="Enter Entity Name"
                  {...form.getInputProps('entityName')}
                />
                <TextInput
                  w="100%"
                  label="MEI (Market Entity Identifier)"
                  name="mei"
                  id="mei"
                  placeholder="Enter MEI"
                  minLength={10}
                  maxLength={10}
                  {...form.getInputProps('mei')}
                  onChange={(e) => form.setFieldValue('mei', e.target.value.toUpperCase())}
                />
                <Select
                  searchable
                  clearable
                  w="100%"
                  label="Institution Type"
                  placeholder="Select Institution Type"
                  data={institutionFinancialTypeOptions}
                  {...form.getInputProps('institutionType')}
                />
              </Group>

              <Group noWrap w="100%">
                <MultiSelect
                  w="100%"
                  label="Entity Profile"
                  placeholder="Select entity profile"
                  searchable
                  data={entityProfiles.filter(profile => profile.isActive).map(profile => ({ label: profile.name, value: profile.id ?? '' }))}
                  value={form.values.entityProfile}
                  onChange={(e: any) => handleEntityProfileChange(e, form as any)}
                />
                <Select
                  error
                  w="100%"
                  label="Entity Type"
                  placeholder="Select contact type"
                  searchable
                  data={entityTypeOptions}
                  {...form.getInputProps('entityType')}
                />
                <Select
                  w="100%"
                  label="KYC Type"
                  placeholder="Enter KYC Type"
                  searchable
                  data={kycTypeOptions}
                  {...form.getInputProps('kycType')}
                />
              </Group>

              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="Address"
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Enter address"
                  {...form.getInputProps('address')}
                />
                <TextInput
                  w="100%"
                  label="Address Line 2"
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Apt, Suite, etc. - Optional"
                  {...form.getInputProps('address2')}
                />
              </Group>

              <Group noWrap w="100%">
                <Select
                  w="100%"
                  label="Country/Region"
                  name="country"
                  id="country"
                  placeholder="Select country/region"
                  searchable
                  data={countries}
                  {...form.getInputProps('country')}
                />
                <TextInput
                  w="100%"
                  label="City"
                  type="text"
                  name="city"
                  id="city"
                  placeholder="Enter city"
                  {...form.getInputProps('city')}
                />
              </Group>

              <Group noWrap w="100%">
                {form.values.country === 'US' ? (
                  <Select
                    w="100%"
                    label="State"
                    name="state"
                    id="state"
                    placeholder="Select state"
                    searchable
                    data={states}
                    {...form.getInputProps('state')}
                  />
                ) : (
                  <TextInput
                    w="100%"
                    label="State/Region"
                    type="text"
                    name="state"
                    id="state"
                    placeholder="Enter state/region"
                    {...form.getInputProps('state')}
                  />
                )}
                <TextInput
                  w="100%"
                  label="ZIP/Postal Code"
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  placeholder="Enter ZIP/Postal Code"
                  {...form.getInputProps('zipCode')}
                />
              </Group>

              <span className="form-subtitle-large">
                Identifiers
              </span>

              <Divider my={0} />

              {form.values.entityType && (
                <Group noWrap w="100%">
                  {(() => {
                    const hierarchy = entityTypes.find(type => type.id === form.values?.entityType)?.hierarchy;
                    return hierarchy && [EntityTypeHierarchy.HierarchyFundManager, EntityTypeHierarchy.HierarchyBoth].includes(hierarchy);
                  })() && (
                      <>
                        <Select
                          w="100%"
                          label="Enter fund manager"
                          placeholder="Enter fund manager"
                          name="fundManager"
                          id="fundManager"
                          searchable
                          data={fundManagerEntityNames}
                          {...form.getInputProps('fundManager')}
                          onChange={(e: string) => {
                            changeFundManagerOrMei('fundManager', e)
                          }}
                        />
                        <Select
                          w="100%"
                          label="Fund Manager MEI"
                          placeholder="Fund Manager MEI"
                          name="fundManagerMei"
                          id="fundManagerMei"
                          searchable
                          data={fundManagerEntityMeis}
                          {...form.getInputProps('fundManagerMei')}
                          onChange={(e: string) => {
                            changeFundManagerOrMei('fundManagerMei', e)
                          }}
                        />
                      </>
                    )}


                  {(() => {
                    const hierarchy = entityTypes.find(type => type.id === form.values?.entityType)?.hierarchy;
                    return hierarchy && [EntityTypeHierarchy.HierarchyParent, EntityTypeHierarchy.HierarchyBoth].includes(hierarchy);
                  })() && (
                      <>
                        <TextInput
                          w="100%"
                          label="Parent"
                          name="lenderParent"
                          id="lenderParent"
                          placeholder="Name of legal parent if different from entity"
                          {...form.getInputProps('parent')}
                        />
                        <TextInput
                          w="100%"
                          label="Parent MEI"
                          name="parentMei"
                          id="parentMei"
                          maxLength={10}
                          placeholder="Parent MEI"
                          {...form.getInputProps('parentMei')}
                          onChange={(e) => form.setFieldValue('parentMei', e.target.value.toUpperCase())}
                        />
                      </>
                    )}
                </Group>
              )}
              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="Internal Entity ID"
                  name="internalId"
                  id="internalId"
                  placeholder="Enter Internal Entity ID"
                  {...form.getInputProps('internalId')}
                />
                <TextInput
                  w="100%"
                  label="LEI"
                  name="legalEntityId"
                  id="legalEntityId"
                  placeholder="Enter LEI unique 20 digit code number"
                  maxLength={20}
                  {...form.getInputProps('legalEntityId')}
                  onChange={(e) => form.setFieldValue('legalEntityId', e.target.value.toUpperCase())}
                />
                <TextInput
                  w="100%"
                  label="CEI"
                  name="CEI"
                  id="cei"
                  placeholder="CUSIP Entity Identifier 10 character"
                  maxLength={10}
                  {...form.getInputProps('cei')}
                  onChange={(e) => form.setFieldValue('cei', e.target.value.toUpperCase())}
                />
              </Group>

              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="NAIC Code"
                  name="naicCode"
                  id="naicCode"
                  placeholder="Enter NAIC Code"
                  maxLength={6}
                  {...form.getInputProps('naicCode')}
                  onChange={(e) => { form.setFieldValue('naicCode', e.target.value.toUpperCase()) }}
                />
                <TextInput
                  w="100%"
                  label="CRN"
                  name="crn"
                  id="crn"
                  minLength={8}
                  maxLength={8}
                  placeholder="UK Company Registration Number"
                  {...form.getInputProps('crn')}
                  onChange={(e) => form.setFieldValue('crn', e.target.value.toUpperCase())}
                />
                <TextInput
                  w="100%"
                  label="EIN"
                  name="ein"
                  id="ein"
                  placeholder="Enter EIN"
                  {...form.getInputProps('ein')}
                  onChange={(e) => form.setFieldValue('ein', e.target.value.toUpperCase())}
                  maxLength={9}
                />
              </Group>

              <Group noWrap w="100%">
                <TextInput
                  w="100%"
                  label="GIIN (Global Intermediary Identification Number)"
                  name="giin"
                  id="giin"
                  placeholder="e. g. XXXXXX.XXXXX.XX.XXX"
                  {...form.getInputProps('giin')}
                  onChange={(e) => form.setFieldValue('giin', e.target.value.toUpperCase())}
                  maxLength={19}
                />
              </Group>

              <span className="form-subtitle-large">
                Tax/KYC
              </span>

              <Divider my={0} />

              <Group noWrap w="100%">
                <Select
                  w="100%"
                  label="Entity Reference"
                  placeholder="Select entity reference"
                  searchable={true}
                  data={entityReferenceOptions}
                  {...form.getInputProps('entityReference')}
                />
                <Select
                  w="100%"
                  label="US Tax Form Type"
                  placeholder="Select tax form type"
                  searchable={true}
                  data={taxFormOptions}
                  {...form.getInputProps('usTaxFormType')}
                />
                <Select
                  w="100%"
                  label="Tax Form Status"
                  placeholder="Select tax form status"
                  searchable={true}
                  data={taxFormStatusOptions}
                  {...form.getInputProps('taxFormStatus')}
                />
              </Group>

              <Group noWrap w="100%">
                <Select
                  w="100%"
                  withAsterisk
                  required
                  label="KYC Status"
                  placeholder="Enter KYC status"
                  searchable={true}
                  data={kycStatusOptions}
                  {...form.getInputProps('kycStatus')}
                />
                <Select
                  w="100%"
                  label="Country of Incorporation"
                  placeholder="Select country"
                  searchable
                  data={countries}
                  {...form.getInputProps('countryOfIncorporation')}
                />
                <Select
                  w="100%"
                  label="Country of Tax Residence"
                  placeholder="Select country"
                  searchable
                  data={countries}
                  {...form.getInputProps('countryOfTaxResidence')}
                />
              </Group>
              {form.values.countryOfTaxResidence === 'GB' ?
                <Group noWrap w="100%">
                  <TextInput
                    w="100%"
                    label="UK Treaty Passport #"
                    name="ukTreatyPassportNumber"
                    id="ukTreatyPassportNumber"
                    placeholder="e. g. 3/M365185/DTTP"
                    {...form.getInputProps('ukTreatyPassportNumber')}
                  />
                  <CustomDatePicker
                    w="100%"
                    name={'ukTreatyPassportExpiryDate'}
                    label={'UK Treaty Passport Expiry Date'}
                    required={
                      form.values.ukTreatyPassportNumber !== null &&
                      form.values.ukTreatyPassportNumber.length > 0
                    }
                    date={form.values.ukTreatyPassportExpiryDate as Date}
                    setDate={value => form.setFieldValue('ukTreatyPassportExpiryDate', value)}
                    holidayCalendars={[]}
                  />
                </Group>
                : null}

              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  gap: '.5rem',
                  justifyContent: 'flex-end',
                }}
              >
                {isStepper ? (
                  <>
                    <Group noWrap w="100%">
                      <PrimaryButton
                        disabled={!form.isValid()}
                        className="form-button"
                        loading={isLoading}
                        type="submit"
                        w="100%"
                      >
                        Save and Proceed
                      </PrimaryButton>
                      <PrimaryButton
                        disabled={!form.isValid()}
                        className="form-button"
                        loading={isLoading}
                        onClick={() => submitAndExit()}
                        w="100%"
                      >
                        Save and Exit
                      </PrimaryButton>
                    </Group>
                  </>
                ) : (
                  <>
                    <PrimaryButton
                      className="form-button"
                      w="100%"
                      disabled={!form.isValid()}
                      loading={isLoading}
                      type="submit"
                      text="Save"
                    />
                    <PrimaryButton
                      className="form-button"
                      w="100%"
                      onClick={() => form.reset()}
                      text="Clear"
                    />
                  </>
                )}
              </div>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}
