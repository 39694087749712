import React from 'react'
import Pill, { PillColor } from 'app/views/components/Table/Pill'
import { default as Pill2 } from 'app/views/components/Table/PillUpdated'

export enum pillState {
  'Draft' = 'Draft',
  'Submitted' = 'Submitted',
  'Approved' = 'Approved',
  'DraftAmendment' = 'Draft Amendment',
  'SubmittedAmendment' = 'Submitted Amendment',
  'Terminated' = 'Terminated',
}

type StatusPillProps = {
  state: pillState | string
  additionalState?: string
  updated?: boolean
}

const StatusPill: React.FC<StatusPillProps> = ({ state, additionalState, updated }) => {
  let color = PillColor.PINK

  if (state === pillState.Submitted || state === pillState.SubmittedAmendment) {
    color = PillColor.ORANGE
  } else if (state === pillState.Approved) {
    color = PillColor.GREEN
  } else if (state === pillState.Draft) {
    color = PillColor.GREY
  } else if (state === pillState.DraftAmendment) {
    color = PillColor.PURPLE
  } else if (state === pillState.Terminated) {
    color = PillColor.RED
  }

  return (
    <>
      {updated ? <Pill2
        text={additionalState ? state + ' ' + (additionalState === "Amendments" ? "Amendment" : additionalState) : state}
        color={color as PillColor}
        w="100px"
      /> : <Pill
        text={additionalState ? state + ' ' + (additionalState === "Amendments" ? "Amendment" : additionalState) : state}
        color={color}
        w="100px"
      />}
    </>
  )
}

export default StatusPill
