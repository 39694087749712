import React from 'react';
import { Box, Group, Text, Switch } from '@mantine/core';
import { EntityTypes } from 'app/models/entity-type-params';
import { groupedRoles, roleLabels } from './roles-and-label';

interface RolesSectionProps {
    roles: EntityTypes[];
    errorMsg?: string | null;
    hierarchy: string;
    onToggleRole: (role: EntityTypes, checked: boolean) => void;
}

const RolesSection: React.FC<RolesSectionProps> = ({ roles, hierarchy, errorMsg, onToggleRole }) => {

    const isFieldVisible = (field: EntityTypes) => {
        if (hierarchy === "HierarchyParent") {
            return field !== EntityTypes.FundManagerName && field !== EntityTypes.FundManagerMEI;
        }
        if (hierarchy === "HierarchyFundManager") {
            return field !== EntityTypes.Parent && field !== EntityTypes.ParentMEI;
        }
        return true;
    };

    return (
        <Box className="roles-container">
            <Text className="roles-title">Mandatory Fields:</Text>
            <Group spacing="xl" align="flex-start">
                {Object.entries(groupedRoles).map(([groupName, fields]) => (
                    <Box key={groupName} mt={40} sx={{ flex: 1 }}>
                        <Text className="roles-subtitle">
                            {groupName}
                        </Text>
                        {fields
                          .filter(isFieldVisible)
                          .map((field) => (
                            <Box key={field} className="role-item" mb="sm">
                                <Switch
                                    checked={roles.includes(field ?? '')}
                                    onChange={(e) => onToggleRole(field, e.currentTarget.checked)}
                                    size="md"
                                    classNames={{
                                        track: `custom-switch-track ${roles.includes(field) ? 'active' : ''}`,
                                        thumb: `custom-switch-thumb ${roles.includes(field) ? 'active' : 'inactive'}`,
                                    }}
                                />
                                <Text className="role-text" data-role-label={roleLabels[field]}>
                                    {roleLabels[field]}
                                </Text>
                            </Box>
                        ))}
                    </Box>
                ))}
            </Group >
            {errorMsg && <Text color="red">{errorMsg}</Text>}
        </Box >
    );
};

export default RolesSection;
