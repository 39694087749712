import React, { useEffect } from 'react'
import { Group, Menu, Stack } from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import { TradeEntry } from './trade-entry'
import { loadAllMasterTrades } from 'app/state/ducks/master-trades/thunks'
import { loadAllSubTrades } from 'app/state/ducks/sub-trades/thunks'
import { useDispatch } from 'react-redux'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useNavigate } from 'react-router-dom'


interface props {
  tab: string
}

export function TradeManagementDrillPage({ tab }: props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(loadAllMasterTrades())
    dispatch(loadAllSubTrades())
  }, [])


  const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: React.ReactNode; } } = {
    tradeEntry: { route: '/tradeentry', label: 'TRADES', active: tab === 'tradeEntry' ? true : false, pageRender: <TradeEntry /> },
  }

  return (
    <Stack>
      <Group w="100%" position="apart" className='tabsGapsActivity'>
        <Group position="right">
          <SegmentControlUpdated tabs={tabs} />
        </Group>
        <Group noWrap>
          <Menu shadow="md" width={200} position="bottom-start">
            <Menu.Target>
              <PrimaryDropDown isCreateNew >Action</PrimaryDropDown>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={() => {
                navigate('/tradeentry/create', { state: { tab: 'tradeEntryCreation' } });
              }}
              > Trade</Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
      {tabs[tab].pageRender}
    </Stack>
  )
}
