import * as types from './types'
import { PortfolioParams } from 'app/models/portfolio-params'
import { createReducer } from 'app/state/utils'

export interface PortfolioState {
  portfolios: PortfolioParams[]
  portfolio?: PortfolioParams
  isLoading: boolean
}

const initialState: PortfolioState = {
  portfolios: [],
  portfolio: undefined,
  isLoading: false,
}

const portfoliosReducer = createReducer(initialState)({
  [types.GET_PORTFOLIOS]: (state: any, payload: any) => {
    return { ...state, portfolios: payload.payload }
  },
  [types.GET_PORTFOLIO]: (state: any, payload: any) => {
    return { ...state, portfolio: payload.payload }
  },
  [types.ADD_PORTFOLIO]: (state: any, payload: any) => {
    return {
      ...state,
      portfolios: [payload.payload.data, ...state.portfolios],
    }
  },
  [types.EDIT_PORTFOLIO]: (state: any, payload: any) => {
    const index = state.portfolios.findIndex(
      (portfolio: { id: any }) =>
        portfolio.id === payload.payload.data.id,
    )
    const newArray = [...state.portfolios]
    newArray[index] = { ...payload.payload.data }
    return {
      ...state,
      portfolios: newArray,
      portfolio: { ...payload.payload.data },
    }
  },
  [types.DELETE_PORTFOLIOS]: (state: any, payload: any) => {
    const filteredPortfolios = state.portfolios.filter(
      (portfolio: any) => {
        return portfolio.id !== payload.payload[0].id
      },
    )

    return {
      ...state,
      portfolios: filteredPortfolios,
    }
  },
  [types.START_LOADING_PORTFOLIOS]: (state: PortfolioState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_PORTFOLIOS]: (state: PortfolioState) => {
    return { ...state, isLoading: false }
  },
})

export default portfoliosReducer