import { useDispatch, useSelector } from 'react-redux'

// ** Hooks
import { useEffect, useState } from 'react'
import { formatDateToUTC } from 'app/utils/util-functions'
import config from 'app/config/config'
import { isNotEmpty, useForm } from '@mantine/form'
import { Group, Stack } from '@mantine/core'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import {
  loadInterestByIndex,
  saveRate,
} from 'app/state/ducks/interest-rates/thunks'
import { InterestRateParams } from 'app/models/interest-rate'
// import TermInterestRateForm2 from './term-interest-rates-form-2'
import TermInterestRateForm from './term-interest-rates-form'
import NonTermInterestRateForm from './non-term-interest-rates-form'
import { getInterestRatess } from 'app/state/ducks/interest-rates/selectors'
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

type Props = {
  closeModal: () => void
  index: IndexRateOptionParams
}

const DailyRatesFormLogic: React.FC<Props> = ({ closeModal, index }) => {
  const dispatch = useDispatch()

  const dailyRates = useSelector(getInterestRatess)
  const businessDate = useSelector(getBusinessDate)

  const [alreadyExists, setAlreadyExists] = useState(false)

  const form = useForm({
    initialValues: {
      indexId: index.id,
      id: '',
      currency: index.currency,
      index: index.indexOption ?? '',
      effectiveDate: businessDate,
      riskFreeRate: index?.riskFreeRate ? 'True' : 'False',
      type: index.indexType,
      termRates:
        index.indexType !== 'TermIndex'
          ? null
          : {
            overnight: index.contractPeriods.includes('Overnight')
              ? 0.0
              : null,
            oneWeek: index.contractPeriods.includes('OneWeek') ? 0.0 : null,
            twoWeeks: index.contractPeriods.includes('TwoWeeks') ? 0.0 : null,
            threeWeeks: index.contractPeriods.includes('ThreeWeeks')
              ? 0.0
              : null,
            oneMonth: index.contractPeriods.includes('OneMonth') ? 0.0 : null,
            twoMonths: index.contractPeriods.includes('TwoMonths')
              ? 0.0
              : null,
            threeMonths: index.contractPeriods.includes('ThreeMonths')
              ? 0.0
              : null,
            fourMonths: index.contractPeriods.includes('FourMonths')
              ? 0.0
              : null,
            fiveMonths: index.contractPeriods.includes('FiveMonths')
              ? 0.0
              : null,
            sixMonths: index.contractPeriods.includes('SixMonths')
              ? 0.0
              : null,
            sevenMonths: index.contractPeriods.includes('SevenMonths')
              ? 0.0
              : null,
            eightMonths: index.contractPeriods.includes('EightMonths')
              ? 0.0
              : null,
            nineMonths: index.contractPeriods.includes('NineMonths')
              ? 0.0
              : null,
            tenMonths: index.contractPeriods.includes('TenMonths')
              ? 0.0
              : null,
            elevenMonths: index.contractPeriods.includes('ElevenMonths')
              ? 0.0
              : null,
            oneYear: index.contractPeriods.includes('OneYear') ? 0.0 : null,
          },
      floatingRate: index.indexType !== 'FloatingIndex' ? null : 0.0,
      overnightRate: index.indexType !== 'OvernightIndex' ? null : 0.0,
    },
    validate: {
      indexId: value => (value === '' ? 'Invalid Index Type' : null),
      effectiveDate: isNotEmpty('Must select a start date'),
      floatingRate: value =>
        index.indexType === 'FloatingIndex' && value === 0.0
          ? 'Must enter a floating rate'
          : null,
      overnightRate: value =>
        index.indexType === 'OvernightIndex' && value === 0.0
          ? 'Must enter a overnight rate'
          : null,
      termRates: {
        overnight: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('overnight') &&
            value === 0.0
            ? 'Must enter a overnight rate'
            : null,
        oneWeek: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('oneWeek') &&
            value === 0.0
            ? 'Must enter a one week rate'
            : null,
        twoWeeks: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('twoWeeks') &&
            value === 0.0
            ? 'Must enter a two week rate'
            : null,
        threeWeeks: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('threeWeeks') &&
            value === 0.0
            ? 'Must enter a three week rate'
            : null,
        oneMonth: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('oneMonth') &&
            value === 0.0
            ? 'Must enter a one month rate'
            : null,
        twoMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('twoMonths') &&
            value === 0.0
            ? 'Must enter a two month rate'
            : null,
        threeMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('threeMonths') &&
            value === 0.0
            ? 'Must enter a three month rate'
            : null,
        fourMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('fourMonths') &&
            value === 0.0
            ? 'Must enter a four month rate'
            : null,
        fiveMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('fiveMonths') &&
            value === 0.0
            ? 'Must enter a five month rate'
            : null,
        sixMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('sixMonths') &&
            value === 0.0
            ? 'Must enter a six month rate'
            : null,
        sevenMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('sevenMonths') &&
            value === 0.0
            ? 'Must enter a seven months rate'
            : null,
        eightMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('eightMonths') &&
            value === 0.0
            ? 'Must enter a eight months rate'
            : null,
        nineMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('nineMonths') &&
            value === 0.0
            ? 'Must enter a nine months rate'
            : null,
        tenMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('tenMonths') &&
            value === 0.0
            ? 'Must enter a ten months rate'
            : null,
        elevenMonths: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('elevenMonths') &&
            value === 0.0
            ? 'Must enter a eleven months rate'
            : null,
        oneYear: value =>
          index.indexType === 'TermIndex' &&
            index.contractPeriods.includes('oneYear') &&
            value === 0.0
            ? 'Must enter a one year rate'
            : null,
      },
    },
    transformValues: values => ({
      ...values,
      floatingRate:
        values.floatingRate && values.floatingRate > 0
          ? values.floatingRate
          : null,
      overnightRate:
        values.overnightRate && values.overnightRate > 0
          ? values.overnightRate
          : null,
      termRates:
        values.termRates === null
          ? null
          : {
            overnight:
              values.termRates.overnight && values.termRates.overnight > 0
                ? values.termRates.overnight
                : null,
            oneWeek:
              values.termRates.oneWeek && values.termRates.oneWeek > 0
                ? values.termRates.oneWeek
                : null,
            twoWeeks:
              values.termRates.twoWeeks && values.termRates.twoWeeks > 0
                ? values.termRates.twoWeeks
                : null,
            threeWeeks:
              values.termRates.threeWeeks && values.termRates.threeWeeks > 0
                ? values.termRates.threeWeeks
                : null,
            oneMonth:
              values.termRates.oneMonth && values.termRates.oneMonth > 0
                ? values.termRates.oneMonth
                : null,
            twoMonths:
              values.termRates.twoMonths && values.termRates.twoMonths > 0
                ? values.termRates.twoMonths
                : null,
            threeMonths:
              values.termRates.threeMonths && values.termRates.threeMonths > 0
                ? values.termRates.threeMonths
                : null,
            fourMonths:
              values.termRates.fourMonths && values.termRates.fourMonths > 0
                ? values.termRates.fourMonths
                : null,
            fiveMonths:
              values.termRates.fiveMonths && values.termRates.fiveMonths > 0
                ? values.termRates.fiveMonths
                : null,
            sixMonths:
              values.termRates.sixMonths && values.termRates.sixMonths > 0
                ? values.termRates.sixMonths
                : null,
            sevenMonths:
              values.termRates.sevenMonths && values.termRates.sevenMonths > 0
                ? values.termRates.sevenMonths
                : null,
            eightMonths:
              values.termRates.eightMonths && values.termRates.eightMonths > 0
                ? values.termRates.eightMonths
                : null,
            nineMonths:
              values.termRates.nineMonths && values.termRates.nineMonths > 0
                ? values.termRates.nineMonths
                : null,
            tenMonths:
              values.termRates.tenMonths && values.termRates.tenMonths > 0
                ? values.termRates.tenMonths
                : null,
            elevenMonths:
              values.termRates.elevenMonths &&
                values.termRates.elevenMonths > 0
                ? values.termRates.elevenMonths
                : null,
            oneYear:
              values.termRates.oneYear && values.termRates.oneYear > 0
                ? values.termRates.oneYear
                : null,
          },
      effectiveDate: formatDateToUTC(values.effectiveDate),
      indexId: { id: index.id, admin: index.public },
    }),
  })

  useEffect(() => {
    dispatch(loadInterestByIndex(index))
  }, [index])

  const [isLoading, setIsLoading] = useState(false)

  async function onSubmit(values: any) {
    setIsLoading(true)
    const interestRate: InterestRateParams = {
      ...values,
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }
    const response: any = await dispatch(saveRate(interestRate))
    setIsLoading(false)
    if (response.success) {
      closeModal()
      form.reset()
      SuccessNotification({
        title: 'Successful Update',
        message: 'Saved Interest Rate Option',
      })
    } else {
      ErrorNotification({
        title: response.payload,
        message: 'Please Check Inputs',
      })
    }
  }

  return (
    <FormWrapper
      title={
        'Create Daily Rate for ' + index.indexOption
      }
      withCloseIcon
      closeModal={closeModal}
    >
      <div className="content">
        <form onSubmit={form.onSubmit(values => onSubmit(values))}>
          <div className="create-new">
            <Stack>
              {index.indexType === 'TermIndex' ? (
                // this is just diferent structired form for term rates depends of product we will keep one of them
                <TermInterestRateForm
                  form={form}
                  index={index}
                  allRates={dailyRates}
                  alreadyExists={alreadyExists}
                  setAlreadyExists={setAlreadyExists}
                />
              ) : (
                //  <TermInterestRateForm2 form={form} index={index} allRates={dailyRates} alreadyExists={alreadyExists} setAlreadyExists={setAlreadyExists} />
                <NonTermInterestRateForm
                  form={form}
                  index={index}
                  allRates={dailyRates}
                  alreadyExists={alreadyExists}
                  setAlreadyExists={setAlreadyExists}
                />
              )}
              <Group noWrap pt='20px' pb='5px'>
                <PrimaryButton
                  w="100%"
                  disabled={!form.isValid() || alreadyExists}
                  loading={isLoading}
                  type="submit"
                  text="Submit"
                />

                <PrimaryButton
                  disabled={alreadyExists}
                  w="100%"
                  onClick={() => form.reset()}
                  text="Clear"
                />
              </Group>
            </Stack>
          </div>
        </form>
      </div>
    </FormWrapper>
  )
}

export default DailyRatesFormLogic
