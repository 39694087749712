import {
    Anchor,
    Breadcrumbs,
    Group,
    Stack,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { useNavigate, useParams } from 'react-router-dom'
import DealPage from './deal-page'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import DealHeader from './deal-header'
import { useEffect } from 'react'
import { DealParams } from 'app/models/deal-params'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { getDealAmendments } from 'app/state/ducks/deal-amendments/selectors'
import { loadAllDealAmendments } from 'app/state/ducks/deal-amendments/thunks'
import { loadAllDeals, setOneDeal } from 'app/state/ducks/deals/thunks'
import DealActions from './deal-actions'


interface props {
    tab: string
}

export function DealDrillPage({ tab }: props) {
    const { dealId, status } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const deal: DealParams | undefined = useSelector(getDeal)
    const allDeals: DealParams[] = useSelector(getDeals)
    const dealAmendments: DealParams[] = useSelector(getDealAmendments)

    useEffect(() => {
        if (allDeals) {
            const selectedDeal = allDeals.find(deal => deal.id === dealId)
            if (selectedDeal) {
                selectedDeal.amendment = false
                dispatch(setOneDeal(selectedDeal))
            }
        }
        if (dealAmendments) {
            const amended_deal = dealAmendments.find(
                amendment => amendment.id === dealId
            )
            if (amended_deal) {
                amended_deal.amendment = true
                dispatch(setOneDeal(amended_deal))
            }
        }
    }, [allDeals, dealId, dealAmendments])

    useEffect(() => {
        dispatch(loadAllDeals())
        dispatch(loadEntities())
    }, [dealId])

    useEffect(() => {
        dispatch(loadAllFacilities())
        dispatch(loadAllDealAmendments())
    }, [])

    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element } } = {
        dealDetails: { route: `/dealmanagement/deal/${dealId}/${status}`, label: 'DEAL', active: tab === 'dealDetails' ? true : false, pageRender: <DealPage deal={deal} /> },
    }

    const items = [
        { title: 'Deal Management', href: '/dealmanagement' },
        { title: deal?.dealName ?? 'Deal' },
    ].map((item, index) => (
        <Anchor
            onClick={item.href ? () => navigate(item.href) : () => null}
            key={index}
            color="#374151"
            fw={500}
            fz="14px"
        >
            {item.title}
        </Anchor>
    ))

    return (
        <Stack w="100%">
            <Breadcrumbs
                separator={<IonIcon name="chevron-forward-outline" />}
            >
                {items}
            </Breadcrumbs>
            {deal ? <DealHeader deal={deal} showStatusPill={true} /> : null}
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    {deal && <DealActions primaryInput={true} row={deal} withRedirection extended />}
                </Group>
            </Group>
            <Group noWrap w='100%' mt='10px'>
                {tabs[tab].pageRender}
            </Group>
        </Stack>
    )
}