import React from 'react';
import { Box, Group, Text, Switch } from '@mantine/core';
import { EntityRole } from 'app/models/entity-profile-params';

interface RolesSectionProps {
    roles: EntityRole[];
    errorMsg?: string | null;
    onToggleRole: (role: EntityRole, checked: boolean) => void;
}


const RolesSection: React.FC<RolesSectionProps> = ({ roles, errorMsg, onToggleRole }) => {
    const allRoles: EntityRole[] = [
        EntityRole.AdminAgentRole,
        EntityRole.BorrowerRole,
        EntityRole.BusinessProcessOutsourcerRole,
        EntityRole.CustodianRole,
        EntityRole.FundAdminRole,
        EntityRole.GuarantorRole,
        EntityRole.LenderRole,
        EntityRole.LetterOfCreditRole,
        EntityRole.ServicerRole,
        EntityRole.ThirdPartyRole,
        EntityRole.TrusteeRole,
    ];

    const roleLabels: { [key in EntityRole]: string } = {
        [EntityRole.BorrowerRole]: 'Borrower',
        [EntityRole.LenderRole]: 'Lender',
        [EntityRole.LetterOfCreditRole]: 'Letter Of Credit (Beneficiary)',
        [EntityRole.GuarantorRole]: 'Guarantor',
        [EntityRole.ThirdPartyRole]: 'Third Party',
        [EntityRole.AdminAgentRole]: 'Admin Agent',
        [EntityRole.CustodianRole]: 'Custodian',
        [EntityRole.TrusteeRole]: 'Trustee',
        [EntityRole.ServicerRole]: 'Servicer',
        [EntityRole.BusinessProcessOutsourcerRole]: 'Business Process Outsourcer',
        [EntityRole.FundAdminRole]: 'Fund Admin',
    };

    return (
        <Box className="roles-container" >
            <Text className="roles-title">
                Roles:
            </Text>
            <Group
                spacing="md"
                mb="md"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    gap: '10px',
                }}
            >
                {allRoles.map((role) => (
                    <Box key={role} className="role-item">
                        <Switch
                            checked={roles.includes(role ?? '')}
                            onChange={(e) => onToggleRole(role, e.currentTarget.checked)}
                            size="md"
                            classNames={{
                                track: `custom-switch-track ${roles.includes(role) ? 'active' : ''}`,
                                thumb: `custom-switch-thumb ${roles.includes(role) ? 'active' : 'inactive'}`,
                            }}
                        />
                        <Text className="role-text">
                            {roleLabels[role]}
                        </Text>
                    </Box>
                ))}
            </Group >
            {errorMsg && <Text color="red">{errorMsg}</Text>}
        </Box >
    );
};

export default RolesSection;
