import * as types from './types'

export const getPortfolios = (payload: any) => ({
  type: types.GET_PORTFOLIOS,
  payload,
})

export const getPortfolio = (payload: any) => ({
  type: types.GET_PORTFOLIO,
  payload,
})

export const addPortfolio = (payload: any) => ({
  type: types.ADD_PORTFOLIO,
  payload,
})

export const editPortfolio = (payload: any) => ({
  type: types.EDIT_PORTFOLIO,
  payload,
})

export const deletePortfolios = (payload: any) => ({
  type: types.DELETE_PORTFOLIOS,
  payload,
})

export const startLoadingPortfolios = () => ({
  type: types.START_LOADING_PORTFOLIOS,
  payload: null,
})

export const stopLoadingPortfolios = () => ({
  type: types.STOP_LOADING_PORTFOLIOS,
  payload: null,
})