import * as types from './types'
import { StrategyParams } from 'app/models/strategy-params'
import { createReducer } from 'app/state/utils'

export interface StrategyState {
  strategies: StrategyParams[]
  strategy?: StrategyParams
  isLoading: boolean
}

const initialState: StrategyState = {
  strategies: [],
  strategy: undefined,
  isLoading: false,
}

const strategiesReducer = createReducer(initialState)({
  [types.GET_STRATEGIES]: (state: any, payload: any) => {
    return { ...state, strategies: payload.payload }
  },
  [types.GET_STRATEGY]: (state: any, payload: any) => {
    return { ...state, strategy: payload.payload }
  },
  [types.ADD_STRATEGY]: (state: any, payload: any) => {
    return {
      ...state,
      strategies: [payload.payload.data, ...state.strategies],
    }
  },
  [types.EDIT_STRATEGY]: (state: any, payload: any) => {
    const index = state.strategies.findIndex(
      (strategy: { id: any }) =>
        strategy.id === payload.payload.data.id,
    )
    const newArray = [...state.strategies]
    newArray[index] = { ...payload.payload.data }
    return {
      ...state,
      strategies: newArray,
      strategy: { ...payload.payload.data },
    }
  },
  [types.DELETE_STRATEGIES]: (state: any, payload: any) => {
    const filteredStrategies = state.strategies.filter(
      (strategy: any) => {
        return strategy.id !== payload.payload[0].id
      },
    )

    return {
      ...state,
      strategies: filteredStrategies,
    }
  },
  [types.START_LOADING_STRATEGIES]: (state: StrategyState) => {
    return { ...state, isLoading: true }
  },
  [types.STOP_LOADING_STRATEGIES]: (state: StrategyState) => {
    return { ...state, isLoading: false }
  },
})

export default strategiesReducer