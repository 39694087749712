import { useEffect, useState } from 'react'
import DatePickerPresentation from './data-picker-presentation'
import { useSelector } from 'react-redux'
import { DatePickerProps, DateValue } from '@mantine/dates'
import { getCalendars } from 'app/state/ducks/holiday-calendars/selectors'
import { HolidayCalendarParams } from 'app/models/holiday-calendar-params'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

type Props = {
  name?: string
  label: string
  date: Date | null
  setDate: React.Dispatch<React.SetStateAction<Date | null>>
  holidayCalendars: string[]
  warning?: boolean
  warningText?: string
  errorMsg?: string | null
  disabled?: boolean
  mindate?: Date
  mantineFormProps?: any
  required?: boolean
  withAsterisk?: boolean
  styles?: any
  clearable?: boolean
  placeholder?: string
} & DatePickerProps

type holidayReturnObj = {
  reason: string
  recommendedDate: string
}

const CustomDatePicker: React.FC<Props> = ({
  label,
  date,
  setDate,
  holidayCalendars,
  warning = false,
  disabled = false,
  warningText,
  mindate,
  mantineFormProps,
  name,
  required = false,
  withAsterisk = false,
  styles,
  errorMsg = null,
  placeholder,
  ...props
}) => {
  const holidays: HolidayCalendarParams[] = useSelector(getCalendars)
  const businessDate = useSelector(getBusinessDate)
  const [dateSuggestionOpen, setDateSuggestionOpen] = useState(false)
  const [holidayArray, setHolidayArray] = useState<HolidayCalendarParams[]>([])
  const [reason, setReason] = useState('')
  const [recommendedDate, setRecommendedDate] = useState('')
  const [opened, setOpened] = useState(false)
  const [warnings, setWarnings] = useState(warning)

  function isHoliday(curDate: string) {
    holidayArray?.map(holiday => {
      const { holidayInfo } = holiday
      holidayInfo.forEach(({ holidayDate }) => {
        if (new Date(holidayDate).toLocaleDateString() == curDate) {
          return true
        }
      })
    })
    return false
  }

  const getHoliday = (curDate: string) => {
    for (const { holidayInfo } of holidayArray) {
      for (const holidayObj of holidayInfo) {
        if (new Date(holidayObj.holidayDate).toLocaleDateString() == curDate) {
          return holidayObj
        }
      }
    }
    return null
  }

  function checkIfWeekend(date: string) {
    const sunday = 0
    const saturday = 6
    const today = new Date(date).getDay()
    return [saturday, sunday].includes(today)
  }

  const validateDate = async (date: any) => {
    let recommendedDate = null
    let returnObj: holidayReturnObj = {
      reason: '',
      recommendedDate: '',
    }

    //check if weekend
    if (checkIfWeekend(date)) {
      //check if Saturday => recommended date = date - 1
      if (new Date(date).getDay() == 6) {
        recommendedDate = new Date(
          new Date(date).setDate(new Date(date).getDate() + 2)
        )
        returnObj = {
          reason: 'Saturday',
          recommendedDate: recommendedDate.toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
        }
      } else {
        //Sunday => recommended date = date + 1
        recommendedDate = new Date(
          new Date(date).setDate(new Date(date).getDate() + 1)
        )
        returnObj = {
          reason: 'Sunday',
          recommendedDate: recommendedDate.toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
        }
      }
    }
    if (isHoliday(date)) {
      recommendedDate = new Date(
        new Date(date).setDate(new Date(date).getDate() + 1)
      )
      const reason = getHoliday(date)?.holidayName ?? ''
      returnObj = {
        reason: reason,
        recommendedDate: recommendedDate.toLocaleDateString('en-us', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      }
    }
    return returnObj
  }
  /*
      1. Check if date selected is in holidayArray
      2. If it is a holiday, check if holiday falls on Saturday or Sunday.
      3. If holiday falls on Saturday, prompt user with message stating that 'This is a holiday. Please go with {Friday date}'. 
      4. If it falls on Sunday, prompt user with message stating that 'This is a holiday. Please go with {Monday date}'. 
      5. If neither, prompt user with message stating that 'This is a holiday. Please go with {next day date}'. 
    */

  const handleDateChange = async (_date: DateValue) => {
    const date = _date?.toLocaleDateString('en-EN')
    const holidayObj = await validateDate(date)
    if (holidayObj.reason != '') {
      setDateSuggestionOpen(true)
      setReason(holidayObj.reason)
      setRecommendedDate(holidayObj.recommendedDate)
    }
    setDate(_date)
  }

  /*
      1. Check holiday calendars
      2. For each calendar, filter holidays and chuck into array
      3. Assign array into setHolidayArray
  */
  useEffect(() => {
    if (Array.isArray(holidays)) {
      const days = holidays.filter((holiday: HolidayCalendarParams) =>
        holidayCalendars?.includes(holiday.name)
      )
      setHolidayArray(days)
    }
  }, [holidayCalendars])

  return (
    <DatePickerPresentation
      required={required}
      withAsterisk={withAsterisk}
      disabled={disabled}
      opened={opened}
      setOpened={setOpened}
      name={name}
      label={label}
      date={date}
      warnings={warnings}
      warningText={warningText}
      setWarnings={setWarnings}
      mindate={mindate}
      mantineFormProps={mantineFormProps}
      handleDateChange={handleDateChange}
      dateSuggestionOpen={dateSuggestionOpen}
      setDateSuggestionOpen={setDateSuggestionOpen}
      recommendedDate={new Date(recommendedDate) ?? businessDate}
      firstDayOfWeek={0}
      reason={reason}
      styles={styles}
      errorMsg={errorMsg}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default CustomDatePicker
