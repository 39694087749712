import { Group, Select, SelectProps } from "@mantine/core";
import { TextLabelInput } from "../TextLabelInput";
import { TextLabelInputTradeView } from "../TextLabelInputTradeView";
import IonIcon from "@reacticons/ionicons";

type Props = SelectProps & {
    label: string;
    wrapperType?: "default" | "trade"; // Optional prop to specify the wrapper type
};

export const NewSelectInput = ({ label, wrapperType = "default", ...props }: Props) => {
    // Determine which wrapper to use
    const Wrapper = wrapperType === "trade" ? TextLabelInputTradeView : TextLabelInput;

    return (
        <Group noWrap className="dataEntrySubSection">
            <Wrapper label={label}>
                <Select
                    classNames={{
                        input: "newInput",
                        wrapper: "newInputWrapper",
                        rightSection: "customRightSection",
                    }}
                    rightSection={<IonIcon size="large" name="chevron-down-outline" />}
                    {...props}
                />
            </Wrapper>
        </Group>
    );
};
