import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getPortfolios,
  getPortfolio,
  addPortfolio,
  editPortfolio,
  deletePortfolios,
  startLoadingPortfolios,
  stopLoadingPortfolios,
} from 'app/state/ducks/portfolios/actions'
import { PortfolioParams } from 'app/models/portfolio-params'

export const savePortfolio =
  (savedPortfolio: PortfolioParams, portfolio?: string) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (portfolio) {
      savedPortfolio.id = portfolio
      return client
        .put(`${apiServerUrl}/api/portfolios`, savedPortfolio)
        .then(portfolio => {
          dispatch(editPortfolio(portfolio))
          return { success: true, payload: portfolio }
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/portfolios`, savedPortfolio)
        .then(portfolio => {
          dispatch(addPortfolio(portfolio))
          return { success: true, payload: portfolio }
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    }
  }

export const loadAllPortfolios = () => async (dispatch: Dispatch) => {
  dispatch(startLoadingPortfolios())
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/portfolios`)
    const portfolios = response.data
    dispatch(getPortfolios(portfolios))
  } catch (e) {
    console.log(e)
    dispatch(getPortfolios([]))
  } finally {
    dispatch(stopLoadingPortfolios())
  }
}

export const loadOnePortfolio =
  (portfolioId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/portfolios/one?id=${portfolioId}`)
      .then(portfolio => {
        return dispatch(
          getPortfolio({ ...portfolio.data, status: status }),
        )
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const removePortfolios =
  (portfolio: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/portfolios?ids[]=${portfolio}`)
      .then(portfolios => {
        dispatch(deletePortfolios(portfolios.data))
        return { success: true, payload: portfolios.data }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
