import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mantine/core'
import { useSelector } from 'react-redux'
import { MasterTradeParams } from 'app/models/master-trade-params'
import { getMasterTradeLoader, getMasterTrades } from 'app/state/ducks/master-trades/selectors'
import ExpandableMantineTable from './trade-enty-table'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import PlusButtonWithPopover from './plus-button-modal'
import { filterByTradeDate } from 'app/utils/util-functions'

interface FilterValues {
  tradeStatus?: string;
  tradeDate?: string;
  tradeAction?: string;
  deal?: string;
  myEntity?: string;
  counterparty?: string;
  agent?: string;
}

export function TradeEntry() {
  const masterTrades: MasterTradeParams[] = useSelector(getMasterTrades)
  const [globalLoading, setGlobalLoading] = useState(false);
  const masterTradeLoader = useSelector(getMasterTradeLoader);
  const [filterValues, setFilterValues] = useState<FilterValues>({});
  const [filteredMasterTrades, setFilteredMasterTrades] = useState<MasterTradeParams[]>(masterTrades);

  useEffect(() => {
    setFilteredMasterTrades(
      masterTrades.filter((trade) => {
        return Object.entries(filterValues || {}).every(([key, value]) => {
          if (!value) return true;
          if (key === 'tradeStatus') return trade.status === value;
          if (key === 'tradeDate') return filterByTradeDate(value as [Date, Date] | [], trade.tradeDate as unknown as string | Date);
          if (key === 'tradeAction') return trade.myEntity === value;
          if (key === 'deal') return trade.dealId?.id === value;
          if (key === 'myEntity') return (trade.myEntity.toLowerCase() === 'buy' && trade.buyerId?.id === value) || (trade.myEntity.toLowerCase() === 'sell' && trade.sellerId?.id === value)
          if (key === 'counterparty') return (trade.myEntity.toLowerCase() === 'sell' && trade.buyerId?.id === value) || (trade.myEntity.toLowerCase() === 'buy' && trade.sellerId?.id === value)
          return true;
        });
      })
    );
  }, [masterTrades, filterValues]);


  useEffect(() => {
    setGlobalLoading(masterTradeLoader);
  }, [masterTradeLoader]);


  return (
    <Stack>
      <SectionTitle>Trade List</SectionTitle>
      <Stack w='100%' className='sectionRemoveGap' style={{ backgroundColor: '#f7f7f7' }}>
        <PlusButtonWithPopover filterValues={filterValues} setFilterValues={setFilterValues} ></PlusButtonWithPopover>
      </Stack>
      <Box style={{ marginBottom: '20px', marginTop: '20px' }}>
        <ExpandableMantineTable title={'Trade List'} masterTrades={filteredMasterTrades} globalLoading={globalLoading} /></Box>
    </Stack >
  )
}
