import { ReactNode } from 'react';
import { ReactComponent as AssignmentsIcon } from '../../../public/nav-bar-icons/assignments-icon.svg'
import { ReactComponent as MyWorkIcon } from '../../../public/nav-bar-icons/mywork-icon.svg'
import { ReactComponent as EntitiesIcon } from '../../../public/nav-bar-icons/entities-icon.svg'
import { ReactComponent as DealsIcon } from '../../../public/nav-bar-icons/deals-icon.svg'
import { ReactComponent as PositionsIcon } from '../../../public/nav-bar-icons/positions-icon.svg'
import { ReactComponent as ServicingIcon } from '../../../public/nav-bar-icons/servicing-icon.svg'
import { ReactComponent as ReleaseQueueIcon } from '../../../public/nav-bar-icons/release-queue-icon.svg'
import { ReactComponent as TradesIcon } from '../../../public/nav-bar-icons/trades-icon.svg'
import { ReactComponent as MaintenanceIcon } from '../../../public/nav-bar-icons/maintenance-icon.svg'
import { ReactComponent as JobsIcon } from '../../../public/nav-bar-icons/jobs-icon.svg'
import { ReactComponent as CalendarsIcon } from '../../../public/nav-bar-icons/calendars-icon.svg'
import { ReactComponent as IroIcon } from '../../../public/nav-bar-icons/iro-icon.svg'
import { ReactComponent as UsersIcon } from '../../../public/nav-bar-icons/users-icon.svg'
import { ReactComponent as EntityProfileIcon } from '../../../public/nav-bar-icons/entity-profile-icon.svg'
import { ReactComponent as EntityTypeIcon } from '../../../public/nav-bar-icons/entity-type-icon.svg'
import { ReactComponent as PortfolioAndStrategyIcon } from '../../../public/nav-bar-icons/portfolio-and-strategy-icon.svg'

export interface Route {
  link: string
  label: string
  icon?: ReactNode | (() => ReactNode);
  alt?: string
  isExtended?: boolean
  isExtendedMain?: boolean
  key?: string;
}
export const Data: Route[] = [
  {
    link: '/mywork',
    label: 'My Work',
    icon: () => <MyWorkIcon style={{ width: '1.5em' }} />,
    alt: 'Account Management Icons',
    key: 'myWork',
  },
  {
    link: '/entitymanagement',
    label: 'Entities',
    icon: () => <EntitiesIcon style={{ width: '1.5em' }} />,
    alt: 'Account Management Icons',
    key: 'entityManagement',
  },
  {
    link: '/dealmanagement',
    label: 'Deals',
    icon: () => <DealsIcon style={{ width: '1.5em' }} />,
    alt: 'deal-management-icon',
    key: 'dealManagement',
  },
  {
    link: '/positions',
    label: 'Positions',
    icon: () => <PositionsIcon style={{ width: '1.5em' }} />,
    alt: 'positions-icon',
    key: 'positions',
  },
  {
    link: '/assetservicing',
    label: 'Servicing',
    icon: () => <ServicingIcon style={{ width: '1.5em' }} />,
    alt: 'asset-servicing-icon',
    key: 'servicing',
  },
  {
    link: '/releasequeue',
    label: 'Release Queue',
    icon: () => <ReleaseQueueIcon style={{ width: '1.5em' }} />,
    alt: 'release-queue-icon',
    key: 'releaseQueue',
  },
  {
    link: '/tradeclosing',
    label: 'Assignments',
    icon: () => <AssignmentsIcon style={{ width: '1.5em' }} />,
    alt: 'assignments-icon',
    key: 'tradeClosing',
  },
  {
    link: '/tradeentry', // remove create when the /tradeentry page is ready
    label: 'Trades',
    icon: () => <TradesIcon style={{ width: '1.5em' }} />,
    alt: 'trades-icon',
    key: 'tradeEntry',
  },
  // {
  //   link: '/reports',
  //   label: 'Reports',
  //   icon: () => <IonIcon name="newspaper" size="large" />,
  //   alt: 'reports-icon',
  //   key: 'reports',
  // },
  {
    link: 'divider',
    label: '',
    key: 'divider',
  },
  {
    link: '/indexrateoptions',
    label: 'Maintenance',
    icon: () => <MaintenanceIcon style={{ width: '1.5em' }} />,
    alt: 'table-maintenance-icon',
    isExtendedMain: false,
    key: 'tableMaintenance',
  },
  {
    link: '/indexrateoptions',
    label: 'Index Rate Options',
    icon: () => <IroIcon style={{ width: '1.5em' }} />,
    alt: 'interest-rate-options-icon',
    isExtended: true,
    key: 'indexRateOptions',
  },
  {
    link: '/usermanagement',
    label: 'Users',
    icon: () => <UsersIcon style={{ width: '1.5em' }} />,
    alt: 'user-management-icon',
    isExtended: true,
    key: 'userManagement',
  },
  {
    link: '/entityprofile',
    label: 'Entity Profile',
    icon: () => <EntityProfileIcon style={{ width: '1.5em' }} />,
    isExtended: true,
    alt: 'Account Management Icons',
    key: 'entityProfile',
  },
  {
    link: '/entitytype',
    label: 'Entity Type',
    icon: () => <EntityTypeIcon style={{ width: '1.5em' }} />,
    isExtended: true,
    alt: 'Account Management Icons',
    key: 'entityType',
  },
  {
    link: '/portfolioandstrategy/portfolio',
    label: 'Portfolio & Strategy',
    icon: () => <PortfolioAndStrategyIcon style={{ width: '1.5em' }} />,
    isExtended: true,
    alt: 'portfolio-and-strategy-icon',
    key: 'portfolioAndStrategy',
  },
  {
    link: '/jobmanagement',
    label: 'Jobs',
    icon: () => <JobsIcon style={{ width: '1.5em' }} />,
    alt: 'job-management-icon',
    key: 'jobManagement',
  },
  {
    link: '/holidaycalendars',
    label: 'Calendars',
    icon: () => <CalendarsIcon style={{ width: '1.5em' }} />,
    alt: 'holiday-calendars-icon',
    key: 'holidayCalendars',
  },
  // {
  //   link: '/simulations',
  //   label: 'Simulate',
  //   icon: <IonIcon name="play-circle" size="large" />,
  //   alt: 'simulation-icon',
  //   key: 'simulations',
  // },
]

export const nestedKeys = ['userManagement', 'indexRateOptions', 'entityProfile', 'entityType', 'portfolioAndStrategy'];