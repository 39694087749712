import React from 'react'
import IndexRateOptions from './index-rate-options-form'
import { Modal } from '@mantine/core'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'

type IndexRateOptionsModalProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  indexRateOption?: IndexRateOptionParams
}

export const IndexRateOptionsModal: React.FC<IndexRateOptionsModalProps> = ({
  setOpen,
  open,
  indexRateOption,
}) => {
  return (
    <Modal
      className="modal-body create-new-form fit-content-modal"
      opened={open}
      onClose={() => setOpen(!open)}
      centered={true}
      size="50%"
      withCloseButton={false}
    >
      <IndexRateOptions
        indexRateOption={indexRateOption}
        closeModal={() => setOpen(false)}
        onHandleSubmit={() => undefined}
      />
    </Modal>
  )
}
