import { Box, Text } from '@mantine/core';

interface Props {
    label: string;
    children?: React.ReactNode;
}
export function TextLabelInputTradeView({ label, children }: Props) {
    return (
        <Box className="textLabelInputContainerTradeView">
            <Text className="textLabelTradeView">
                {label}:
            </Text>
            <Box className="textContentTradeView">
                {children || <Text className="placeholderTextTradeView"></Text>}
            </Box>
        </Box>
    );
} 