import { Button, Group, Modal, Stack, Text } from '@mantine/core'
import { DateInput, DateValue } from '@mantine/dates'
import { DateSuggestionModalConfirmation } from './date-suggestion'
import { ExternalHolidays } from 'app/models/holiday-params'
import { useState } from 'react'

interface Props {
  disabled: boolean
  opened: boolean
  required: boolean
  withAsterisk: boolean
  setOpened: React.Dispatch<React.SetStateAction<boolean>>
  holidays?: ExternalHolidays[]
  name?: string
  label: string
  date: Date | null
  warnings?: boolean
  warningText?: string
  errorMsg?: string | null
  setWarnings: React.Dispatch<React.SetStateAction<boolean>>
  mindate?: Date
  mantineFormProps?: any
  handleDateChange: (date: DateValue) => Promise<void>
  dateSuggestionOpen: boolean
  setDateSuggestionOpen: React.Dispatch<React.SetStateAction<boolean>>
  recommendedDate: Date
  reason: string
  styles?: any
  clearable?: boolean
  placeholder?: string
}

export default function DatePickerPresentation({
  disabled,
  opened,
  required,
  withAsterisk,
  setOpened,
  date,
  label,
  mantineFormProps,
  mindate,
  warnings,
  warningText,
  errorMsg,
  setWarnings,
  handleDateChange,
  dateSuggestionOpen,
  setDateSuggestionOpen,
  recommendedDate,
  reason,
  styles,
  placeholder,
  ...props
}: Props) {
  const [isCancelHovered, setCancelHovered] = useState(false);
  const [isConfirmHovered, setConfirmHovered] = useState(false);

  return (
    <Group w="100%">
      <Stack w="100%">
        <Group w="100%" noWrap>
          <DateInput
            popoverProps={{ withinPortal: true }}
            radius={styles?.borderRadius || 'lg'}
            w="100%"
            withAsterisk={required || withAsterisk}
            disabled={disabled}
            value={date}
            label={label}
            valueFormat="MMMM DD, YYYY"
            {...(mantineFormProps ?? null)}
            onChange={date => warnings ? setOpened(true) : handleDateChange(date)}
            minDate={mindate}
            styles={{
              label: styles?.labelStyles ?? '',
            }}
            {...props}
            error={errorMsg ? errorMsg : null}
            sx={{
              input: {
                backgroundColor: '#F9FAFB',
                // border: errorMsg ? "1px solid #ff4f40" : null,
              },
            }}
            placeholder={placeholder}
          />
        </Group>
      </Stack>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Date change"
      >
        <Stack>
          <Text mb={3}>
            {warningText
              ? warningText
              : "Soft warning: dates don't match with the contract period"}
          </Text>
          <Group position="right" spacing="xs">
            <Button
              style={{
                backgroundColor: isCancelHovered ? '#e2e8f0' : '#d1d5db', // Use hex codes for colors
              }}
              onMouseEnter={() => setCancelHovered(true)} // Handle hover state
              onMouseLeave={() => setCancelHovered(false)} // Reset hover state
              onClick={() => setOpened(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: isConfirmHovered ? '#86efac' : '#4ade80',
              }}
              onMouseEnter={() => setConfirmHovered(true)}
              onMouseLeave={() => setConfirmHovered(false)}
              onClick={() => {
                setWarnings(false);
                setOpened(false);
              }}
            >
              Confirm
            </Button>
          </Group>
        </Stack>
        <DateSuggestionModalConfirmation
          open={dateSuggestionOpen}
          setOpen={setDateSuggestionOpen}
          recommendedDate={recommendedDate}
          reason={reason}
        />
      </Modal>
    </Group>
  )
}
