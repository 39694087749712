import { Badge, BadgeProps } from '@mantine/core'

  // RED = '#FF0000',

export enum PillColor {
  ORANGE = '#FB5607',
  PINK = '#E8006E',
  DARK_BLUE = '#32439A',
  LIGHT_BLUE = '#96D2FA',
  PURPLE = '#8338EC',
  // RED = '#FF0000',
  RED = '#CB3232',
  GREY = '#9CA3AF',
  GREEN = '#287A26',
}

type PillProps = BadgeProps & {
  color: PillColor
  text: string
  outlined?: boolean
}

export default function Pill({ text, color, outlined = false, ...props }: PillProps) {
  return (
    <Badge
      styles={{
        root: {
          fontFamily: 'Plus Jakarta Sans',
          color: outlined ? color : color === PillColor.LIGHT_BLUE ? '#32439A' : 'white',
          borderRadius: '4px',
          padding: '1em',
          fontSize: '1.2em',
          backgroundColor: outlined ? 'transparent' : color,
          border: outlined ? `2px solid ${color}` : 'none',
        },
      }}
      {...props}
    >
      <div className={text.length > 10 ? 'scrollBox' : ''}>
        <span className={text.length > 10 ? 'hoverScroll' : ''}>{text}</span>
      </div>
    </Badge>
  )
}
