import React, { useEffect, useState } from 'react'
import { Paper, Group, Button, TextInput } from '@mantine/core'
import StatusSwitch from '../../../entities/components/status-switch'
import { PortfolioParams } from 'app/models/portfolio-params'
import { useDispatch } from 'react-redux'
import { savePortfolio } from 'app/state/ducks/portfolios/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { useForm } from '@mantine/form'


interface PortfolioFormProps {
    newPortfolio: PortfolioParams | undefined
    setFormExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

const PortfolioForm: React.FC<PortfolioFormProps> = ({ newPortfolio, setFormExpanded }) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleCreatePortfolio = async () => {
        setIsLoading(true)
        try {
            const validatedForm = form.validate()
            if (validatedForm.hasErrors) return
            const values = {
                ...form.values,
                code: form.values.code.toUpperCase(),
            }
            form.validate()
            const result: any = await dispatch(savePortfolio(values, values.id));
            if (!result.success) {
                ErrorNotification({ message: result.payload, title: 'Save Portfolio Failed' })
                return
            }
            SuccessNotification({
                message: 'The portfolio has been successfully saved.',
                title: 'Save Successful',
            })
            setFormExpanded(false)
            form.reset();
        } catch (error) {
            console.log(error)
            ErrorNotification({
                message: 'An error occurred while saving the portfolio.',
                title: 'Save Failed',
            });
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (newPortfolio) {
            form.setValues(newPortfolio)
        }

    }, [newPortfolio]);

    const form: any = useForm({
        initialValues: newPortfolio ?? {
            name: '',
            code: '',
            isActive: false
        },
        validate: {
            name: (value) => (value.trim() === '' ? 'Portfolio Name is required' : null),
            code: (value) => {
                if (!value.trim()) return 'Portfolio Code is required';
                if (!/^[a-zA-Z0-9]{6}$/.test(value)) return 'Portfolio Code must be exactly 6 alphanumeric characters';
                return null;
              },
        },
        validateInputOnBlur: true,
        validateInputOnChange: true,
    })

    return (
        <Paper
            p='lg'
            radius='md'
            className='portfolio-strategy-form-paper'
            style={{ borderRadius: newPortfolio ? '0px 0px 12px 12px' : '12px' }}
        >
            <form onSubmit={handleCreatePortfolio}>
                <Group spacing='lg' mb='md'>
                    <label className='portfolio-strategy-label'>Portfolio Name:</label>
                    <TextInput
                        placeholder='Add Portfolio Name'
                        className='portfolio-strategy-input'
                        {...form.getInputProps('name')}
                    />
                    <label className='portfolio-strategy-label'>Code:</label>
                    <TextInput
                        placeholder='Add Portfolio Code'
                        className='portfolio-strategy-input'
                        maxLength={6}
                        {...form.getInputProps('code')}
                        error={form.errors.code}
                    />
                </Group>

                <Group align='center' mb='md' sx={{ width: '100%' }} position='apart'>
                    <StatusSwitch
                        isActive={form.values.isActive}
                        onToggle={(isActive: boolean) => form.setFieldValue('isActive', isActive)}
                    />
                    <Group className='form-actions-group'>
                        <Button
                            variant='outline'
                            onClick={() => setFormExpanded(false)}
                            className='form-actions-button cancel'
                            loading={isLoading}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleCreatePortfolio}
                            className='form-actions-button save'
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </Group>
                </Group>
            </form>
        </Paper>
    );
};

export default PortfolioForm;
