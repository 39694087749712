import { ContactParams } from "app/models/contact-params"
import { DealParams } from "app/models/deal-params"
import { EntityParams } from "app/models/entity-params"
import { FacilityParams } from "app/models/facility-params"
import { LendersPosition, LendersPositionWithMapping, LoanLenderSharesByFacility } from "app/models/lenders-position-params"
import { LoanParams } from "app/models/loan-params"
import { formatNumberToCurrency } from "app/utils/util-functions"

export function createLendersPositionsWithMapping(positions: LendersPosition[], loanLenderSharesByFacility: LoanLenderSharesByFacility, deals: DealParams[], entities: EntityParams[], facilities: FacilityParams[], contacts: ContactParams[], pastDueLoanFacilityIds: string[]): LendersPositionWithMapping[] {

  return positions.map((position: LendersPosition) => {
    const facility = facilities.find((facility: FacilityParams) => facility.id === position?.contractId?.id)
    const entity = entities.find((ent: EntityParams) => ent.id === position?.lender?.id)
    const agentEntity = entities.find((ent: EntityParams) => ent.id === facility?.adminEntity?.id)
    const opsAdminEntity = contacts.find((contact: ContactParams) => contact?.id === facility?.opsAdmin?.id)
    const deal = deals.find((deal: DealParams) => deal.id === facility?.dealId?.id)
    const settledDateAmount = position?.proRatas ? position.proRatas[position.proRatas.length - 1].amount : ''
    const sdFunded = loanLenderSharesByFacility
      .find((loan) => loan.facilityId === facility?.id)?.lenders?.[position?.lender?.id]?.totalAmount ?? 0;
    const isPastDue = pastDueLoanFacilityIds.includes(facility?.id ?? '');
    const formattedPositions: LendersPositionWithMapping = {
      dealName: deal?.dealName ?? '',
      dealCusip: deal?.cusip ?? '',
      facilityName: facility?.name ?? '',
      facilityType: facility?.facilityType ?? '',
      facilityCusip: facility?.cusip ?? '',
      facilityLxId: facility?.lxId ?? '',
      internalName: '',
      entityName: entity?.entityName ?? '',
      entityMei: entity?.mei,
      agentName: agentEntity?.entityName,
      agentMei: agentEntity?.mei,
      opsAdmin: opsAdminEntity?.name,
      currency: facility?.baseCurrency,
      settledDateAmount: formatNumberToCurrency(Number(settledDateAmount), facility?.baseCurrency ?? 'USD') ?? settledDateAmount,
      tdDate: '',
      sdFunded: formatNumberToCurrency(Number(sdFunded) ?? 0, facility?.baseCurrency ?? 'USD'),
      strategy: '',
      classification: facility?.classification,
      portfolioManager: '',
      id: position.lender.id ?? '',
      isPastDue: isPastDue,
    }
    return formattedPositions
  })
}

// gets all facility Ids that have either a loan or amortization payment that is past due
export const getPastDueFacilityIds = (loans: LoanParams[], facilities: FacilityParams[], businessDate: Date) => {
  const pastDueLoanFacilityIds = 
    loans
      .filter(loan => new Date(loan.endDate) < businessDate)
      .map(loan => loan.facilityId.id)

  const pastDueAmortizationFacilityIds = 
    facilities
      .filter(facility => new Date(String(facility.nextAmortizationPaymentData?.paymentDate)) < businessDate)
      .map(facility => facility.id)

  return Array.from(new Set([...pastDueLoanFacilityIds, ...pastDueAmortizationFacilityIds]));
};
