import React from 'react'
import { Group } from '@mantine/core'
import IonIcon from '@reacticons/ionicons'

type ReactText = string | number
type ReactChild = React.ReactElement | ReactText
type ReactNode = ReactChild | React.ReactPortal | boolean | null | undefined

type FormWrapperProps = {
  title: string
  withCloseIcon?: boolean
  closeModal?: () => void
  children: ReactNode
}

function FormWrapper({ title, withCloseIcon, closeModal, children }: FormWrapperProps) {
  return (
    <div className="form-wrapper">
      {withCloseIcon ?
      (
      <Group w='100%' position='apart'>
      
          <h4 className="form-wrapper-title">{title}</h4>
          <IonIcon
          className='drawerCloseButton'
          name="close-circle"
          onClick={closeModal}
        />
      </Group>
      ) :
      ( 
        <h4 className="form-wrapper-title">{title}</h4> 
      )}
      <div className="form-wrapper-body">{children}</div>
    </div>
  )
}

export default FormWrapper
