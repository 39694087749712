import React, { useState } from 'react'
import { Paper, Group, Badge, Button } from '@mantine/core'
import ItemName from '../../../common/item-name'
import { StrategyParams } from 'app/models/strategy-params'
import { useDispatch } from 'react-redux'
import { removeStrategies } from 'app/state/ducks/strategies/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation'

interface StrategyItemProps {
    strategy: StrategyParams
    isExpanded: boolean
    onEdit: (item: StrategyParams) => void
}

const StrategyItem: React.FC<StrategyItemProps> = ({
    strategy,
    isExpanded,
    onEdit,
}) => {
    const dispatch = useDispatch()

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false) // State to show delete modal

    const handleDeleteStrategy = async (strategyId: string) => {
        const result: any = await dispatch(removeStrategies(strategyId))
        if (!result.success) {
            ErrorNotification({ message: result.payload, title: 'Delete Strategy Failed' })
            return
        }
        SuccessNotification({
            message: 'The strategy has been successfully deleted.',
            title: 'Delete Successful',
        })
    }

    const confirmDelete = () => {
        handleDeleteStrategy(strategy.id ?? '')
        setShowDeleteModal(false)
    }

    return (
        <>
            <Paper
                p="md"
                radius="md"
                className={`paper ${isExpanded ? 'paper-expanded' : ''}`}
            >
                <Group position="apart">
                    <Group spacing="sm">
                        <Badge className={`status-badge ${strategy.isActive ? 'active' : 'inactive'}`}>
                            {strategy.isActive ? 'ACTIVE' : 'INACTIVE'}
                        </Badge>
                        <ItemName name={strategy.name} />
                    </Group>
                    <Group spacing="xs">
                        <Group spacing="xs">
                            <Badge className='portfolio-strategy-badge'>
                                {strategy.code}
                            </Badge>
                            <Button onClick={() => onEdit(strategy)} className="edit-button">
                                Edit
                            </Button>
                            <Button
                                onClick={() => setShowDeleteModal(true)} // Show confirmation modal
                                className="delete-button delete-button-pink"
                            >
                                Delete
                            </Button>
                        </Group>
                    </Group>
                </Group>
            </Paper>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <DeleteConfirmation
                    onConfirm={confirmDelete} // Delete when confirmed
                    onCancel={() => setShowDeleteModal(false)} // Close modal on cancel
                    message="Delete This Strategy?" // Custom message
                />
            )}
        </>
    )
}

export default StrategyItem