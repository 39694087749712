import React from 'react'
import { Box } from '@mantine/core'
import { EntityTypeParams } from 'app/models/entity-type-params'
import { EntityProfileParams } from 'app/models/entity-profile-params'
import { PortfolioParams } from 'app/models/portfolio-params'
import { StrategyParams } from 'app/models/strategy-params'
import ItemPair from './item-pair'


export type ListItem = EntityTypeParams | EntityProfileParams | PortfolioParams | StrategyParams

interface ItemPairListProps<T extends ListItem> {
    items: T[]
    onEdit: (item: T) => void
    expandedItemId: string | null
    setExpandedItemId: React.Dispatch<React.SetStateAction<string | null>>
    newItem: T
    ItemComponent: React.FC<{
        item: T
        isExpanded: boolean
        onEdit: (item: T) => void
    }>
    FormComponent: React.FC<{
        newItem: T
        setFormExpanded: () => void
    }>
}

const ItemPairList = <T extends ListItem>({
    items,
    onEdit,
    expandedItemId,
    setExpandedItemId,
    newItem,
    ItemComponent,
    FormComponent,
}: ItemPairListProps<T>) => {
    // Calculate number of rows and rearrange items into pairs
    const numRows = Math.ceil(items.length / 2)
    const rearrangedItems = []
    for (let i = 0; i < numRows; i++) {
        rearrangedItems.push(items[i], items[i + numRows])
    }
    const validItems = rearrangedItems.filter(Boolean)
    const itemPairs = []
    for (let i = 0; i < validItems.length; i += 2) {
        itemPairs.push(validItems.slice(i, i + 2))
    }

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '10px',
            }}
        >
            {itemPairs.map((itemPair) => {
                const [item1, item2] = itemPair as [T, T | undefined]
                return (
                    <ItemPair
                        key={`${item1.id}${item2 ? `-${item2.id}` : ''}`}
                        item1={item1}
                        isExpanded1={expandedItemId === item1.id}
                        onEdit={onEdit}
                        setExpandedId={setExpandedItemId}
                        newItem1={newItem}
                        item2={item2}
                        isExpanded2={expandedItemId === item2?.id}
                        newItem2={newItem}
                        ItemComponent={ItemComponent}
                        FormComponent={FormComponent}
                    />
                )
            })}
        </Box>
    )
}

export default ItemPairList