import { useEffect, useState } from 'react';
import { Badge, Button, Group, Popover, Text } from '@mantine/core';
import IonIcon from '@reacticons/ionicons';
import { NewSelectInput } from 'app/views/components/inputs/selects/NewSelectInput';
import { useForm } from '@mantine/form';
import { useSelector } from 'react-redux'
import { getDeals } from 'app/state/ducks/deals/selectors';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { DatePickerInput } from '@mantine/dates';
import { TextLabelInputTradeView } from 'app/views/components/inputs/TextLabelInputTradeView';
import moment from 'moment';
import { toTitleCase } from 'app/utils/util-functions';

interface FilterValues {
    tradeStatus?: string;
    tradeDate?: string;
    tradeAction?: string;
    deal?: string;
    myEntity?: string;
    counterparty?: string;
    agent?: string;
}

interface Props {
    filterValues: FilterValues
    setFilterValues: (values: FilterValues) => void;
}

function PlusButtonWithPopover({ filterValues, setFilterValues }: Props) {
    const allDeals = useSelector(getDeals)
    const allEntities = useSelector(getEntities)
    const [isPopoverOpen, setPopoverOpen] = useState(false);

    const togglePopover = () => setPopoverOpen((prev) => !prev);


    const form: any = useForm({
        initialValues: filterValues,
    })

    async function submitForm(event: { preventDefault: () => void }) {
        event.preventDefault()
        setFilterValues(form.values)
        setPopoverOpen(false)
    }

    const removeFilter = (key: keyof FilterValues) => {
        const updatedFilters = { ...filterValues };
        if (key === 'tradeDate') {
            updatedFilters[key] = [] as unknown as string;
        } else {
            updatedFilters[key] = '';
        }
        setFilterValues(updatedFilters);
    };

    useEffect(() => {
        form.setValues(filterValues)
    }, [filterValues]);

    function toBadgeValue(key: string, value: string) {
        if (key === 'deal') return allDeals.find(deal => deal.id === value)?.dealName ?? ''
        if (key === 'myEntity' || key === 'counterparty') return allEntities.find(entity => entity.id === value)?.entityName ?? ''
        if (key === 'tradeDate') return formatTradeDate(key, value)
        return value ?? ''
    }

    const formatTradeDate = (key: string, value: string) => {
        if (key === 'tradeDate' && Array.isArray(value) && value.length === 2) {
            const [startDate, endDate] = value;
            const formattedStartDate = moment(startDate).format('MM/DD/YY');
            const formattedEndDate = moment(endDate).format('MM/DD/YY');
            return `${formattedStartDate} - ${formattedEndDate}`;
        }
        return value;
    };

    return (
        <div className="container">
            {filterValues &&
                Object.entries(filterValues)
                    .filter((value) => value[1] !== undefined && value[1] !== null && value[1] !== '' && value[1].length !== 0) // Filter out empty values
                    .map(([key, value]) => (
                        <Badge
                            key={key}
                            classNames={{
                                root: 'customBadge',
                            }}
                            size="lg"
                            color="blue"
                            rightSection={
                                <IonIcon
                                    name="close-outline"
                                    style={{
                                        cursor: 'pointer', color: '#784595', width: '1.2em', height: '1.2em',
                                    }}
                                    onClick={() => removeFilter(key as keyof FilterValues)}
                                />
                            }
                        >
                            <Group>
                                <Text className='customBadgeText'>{`${toTitleCase(key)}: `}</Text>
                                <Text className='customBadgeTextValue'>{toBadgeValue(key, value ?? '')}</Text>
                            </Group>
                        </Badge>
                    ))
            }
            <Popover
                opened={isPopoverOpen}
                onClose={() => { setPopoverOpen(false) }}
                position="bottom"
                shadow="md"
            >
                <Popover.Target>
                    <Button
                        variant="outline"
                        size="md"
                        className={`plusButton ${isPopoverOpen ? 'plusButtonOpen' : 'plusButtonClosed'}`}
                        onClick={togglePopover}
                    >
                        <IonIcon
                            name="add-outline"
                            style={{
                                width: '2em',
                                height: '2em',
                                color: isPopoverOpen ? '#FFFFFF' : '#32439A',
                                fontWeight: 800,
                            }}
                        />
                    </Button>
                </Popover.Target>
                <Popover.Dropdown
                    style={{
                        transform: 'translateX(30%)'
                    }}>
                    <form onSubmit={submitForm}>
                        <div className="filterMenu">
                            <div className="filterMenuSelected">
                                <div className="filterMenuLeft" >
                                    <NewSelectInput wrapperType="trade" label="Trade Status" data={['Draft', 'Submitted', 'Open', 'Settled']} w="164px" h="42px" clearable placeholder='Trade Status' {...form.getInputProps('tradeStatus')} />
                                    <Group noWrap className="dataEntrySubSection">
                                        <TextLabelInputTradeView label="Trade Date">
                                            <DatePickerInput
                                                w="164px"
                                                h="42px"
                                                placeholder='Trade Date'
                                                valueFormat='MM-DD-YYYY'
                                                type="range"
                                                firstDayOfWeek={0}
                                                classNames={{
                                                    input: 'newInput',
                                                    wrapper: 'newInputWrapper',
                                                    rightSection: 'customRightSection',
                                                }}
                                                rightSection={<IonIcon size="large" name="chevron-down-outline"></IonIcon>}
                                                {...form.getInputProps('tradeDate')}
                                            /></TextLabelInputTradeView>
                                    </Group>
                                    <NewSelectInput wrapperType="trade" label="Trade Action" data={['Buy', 'Sell']} w="164px" h="42px" placeholder='Trade Action' clearable {...form.getInputProps('tradeAction')} />
                                    <NewSelectInput wrapperType="trade" label="Deal" data={allDeals.map((deal) => ({ value: deal.id, label: deal.dealName }))}
                                        w="164px" h="42px" placeholder='Deal' clearable searchable {...form.getInputProps('deal')} />
                                </div>
                                <div className="filterMenuRight">
                                    <NewSelectInput wrapperType="trade" label="My Entity" data={allEntities.map((entity) => ({ value: entity.id, label: entity.entityName }))} w="200px" h="42px" placeholder='My Entity' clearable searchable {...form.getInputProps('myEntity')} /> {/* da vidam filtri za ova entity*/}
                                    <NewSelectInput wrapperType="trade" label="Counterparty" data={allEntities.map((entity) => ({ value: entity.id, label: entity.entityName }))} w="200px" h="42px" placeholder='Counterparty' searchable clearable {...form.getInputProps('counterparty')} /> {/* da vidam filtri za ova  entity */}
                                    <NewSelectInput wrapperType="trade" label="Agent" data={allEntities.map((entity) => ({ value: entity.id, label: entity.entityName }))} w="200px" h="42px" placeholder='Agent' clearable searchable {...form.getInputProps('agent')} />
                                    <Button className="applyButton" type="submit">
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Popover.Dropdown>
            </Popover>
        </div >
    );
}

export default PlusButtonWithPopover;
