import { Menu, Modal } from '@mantine/core';
import PrimaryInput from 'app/views/components/buttons/PrimaryInput';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteIndexRateOption,
  activeIndexRateOption,
  makeInactiveIndexRateOption,
} from './index-rate-option-daml-actions';
import IndexRateOptions from './index-rate-options-form';
import { IndexRateOptionParams } from 'app/models/index-rate-options-params';
import { getIndexRateOptions } from 'app/state/ducks/index-rate-options/selectors';
import { useNavigate } from 'react-router-dom';
import DailyRatesFormLogic from './interest-rates/daily-rates-form-logic';
import { ErrorNotification } from 'app/views/components/notifications/notification';
import IonIcon from '@reacticons/ionicons';
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation';

type Props = {
  row: IndexRateOptionParams;
  withRedirection?: boolean;
};

export default function IndexRateOptionActions({
  row,
  withRedirection,
}: Props) {
  const [openEdit, setOpenEdit] = useState(false);
  const [openDailyRates, setOpenDailyRates] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete modal
  const [indexRateOption, setIndexRateOption] = useState<
    IndexRateOptionParams | undefined
  >(row);
  const indexRateOptions: IndexRateOptionParams[] = useSelector(
    getIndexRateOptions
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function editIndexRateOption(indexRateOptionId: string) {
    setIndexRateOption(indexRateOptions.find(f => f.id == indexRateOptionId));
    setOpenEdit(true);
  }

  function addDailyRates(index: IndexRateOptionParams) {
    setIndexRateOption(index);
    if (index.indexType === 'FixedIndex') {
      ErrorNotification({
        title: 'Error',
        message: 'Cannot add daily rates to a fixed index',
      });
      return;
    } else {
      setOpenDailyRates(true);
    }
  }

  async function activate(): Promise<void> {
    const result = await activeIndexRateOption(row, dispatch);
    if (result && withRedirection) {
      navigate(`/indexrateoptions/indexrateoption/${row.id}/Active`);
    }
  }

  async function deactivate(): Promise<void> {
    const result = await makeInactiveIndexRateOption(row, dispatch);
    if (result && withRedirection) {
      navigate(`/indexrateoptions/indexrateoption/${row.id}/Inactive`);
    }
  }

  const handleDelete = async () => {
    await deleteIndexRateOption(row, dispatch); // Perform delete action
    setShowDeleteModal(false); // Close modal
  };

  return (
    <div>
      <Modal
        className="modal-body create-new-form fit-content-modal"
        opened={openEdit}
        onClose={() => setOpenEdit(false)}
        centered={true}
        size="50%"
        withCloseButton={false}
      >
        <IndexRateOptions
          closeModal={() => setOpenEdit(false)}
          indexRateOption={indexRateOption}
        />
      </Modal>
      {indexRateOption && (
        <Modal
          className="modal-body create-new-form fit-content-modal"
          opened={openDailyRates}
          onClose={() => setOpenDailyRates(false)}
          centered={true}
          size="55%"
          withCloseButton={false}
        >
          <DailyRatesFormLogic
            closeModal={() => setOpenDailyRates(false)}
            index={indexRateOption}
          />
        </Modal>
      )}
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryInput w='159px'>
              Actions
              <IonIcon name='chevron-down-outline' className='primaryInputChevronDown' />
            </PrimaryInput>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() === 'active' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="settings-outline" />}
              onClick={() => addDailyRates(row)}
            >
              Add Daily Rate
            </Menu.Item>
          ) : null}
          <Menu.Item
            icon={<IonIcon className="action-ion-icon" name="settings-outline" />}
            onClick={() => editIndexRateOption(row.id as string)}
          >
            Edit
          </Menu.Item>

          {row?.status?.toLowerCase() === 'inactive' ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => activate()}
            >
              Activate
            </Menu.Item>
          ) : (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name="swap-horizontal-outline" />}
              onClick={() => deactivate()}
            >
              Deactivate
            </Menu.Item>
          )}

          {row?.status?.toLowerCase() === 'inactive' ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name="trash-outline" />}
              onClick={() => setShowDeleteModal(true)} // Show delete modal
            >
              Delete
            </Menu.Item>
          ) : null}
        </Menu.Dropdown>
      </Menu>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmation
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          message="Delete This Index Rate Option?"
        />
      )}
    </div>
  );
}
