import React, { useState } from 'react'
import { Box } from '@mantine/core'
import ControlSection from '../entities/components/control-section'
import ItemPairList from '../common/item-pair-list'
import PortfolioItem from './components/portfolios/portfolio-item'
import PortfolioForm from './components/portfolios/portfolio-form'
import { PortfolioParams } from 'app/models/portfolio-params'
import { useSelector } from 'react-redux'
import { getPortfolios } from 'app/state/ducks/portfolios/selectors'
import { exportToCSV } from 'app/utils/util-functions'

interface PortfolioProps {
    formExpanded: boolean
    setFormExpanded: React.Dispatch<React.SetStateAction<boolean>>
    searchTerm: string
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>
    expandedPortfolioId: string | null
    setExpandedPortfolioId: React.Dispatch<React.SetStateAction<string | null>>
    newPortfolio: PortfolioParams
    setNewPortfolio: React.Dispatch<React.SetStateAction<PortfolioParams>>
}

function Portfolio({ formExpanded,
    setFormExpanded,
    expandedPortfolioId,
    setExpandedPortfolioId,
    searchTerm,
    newPortfolio,
    setNewPortfolio, }: PortfolioProps) {
    const portfolios: PortfolioParams[] = useSelector(getPortfolios)

    const [activePage, setActivePage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const startIdx = (activePage - 1) * itemsPerPage
    const endIdx = startIdx + itemsPerPage
    const filteredPortfolios = portfolios.filter((portfolio) =>
        portfolio.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
        .sort((a, b) => a.name.localeCompare(b.name))
    const currentPortfolios = filteredPortfolios.slice(startIdx, endIdx)
    const handleItemsPerPageChange = (value: string) => {
        setItemsPerPage(parseInt(value, 10))
        setActivePage(1)
    }

    const handleOpenFormForEdit = (portfolioToEdit: PortfolioParams) => {
        setFormExpanded(false)
        setNewPortfolio(portfolioToEdit)
        setExpandedPortfolioId(portfolioToEdit.id || null)
    }

    return (
        <Box sx={{ paddingTop: '20px' }}>
            {formExpanded && (
                <PortfolioForm
                    newPortfolio={undefined}
                    setFormExpanded={(expanded) => {
                        setFormExpanded(expanded)
                    }}
                />
            )}

            <ItemPairList<PortfolioParams>
                items={currentPortfolios}
                expandedItemId={expandedPortfolioId}
                setExpandedItemId={setExpandedPortfolioId}
                onEdit={handleOpenFormForEdit}
                newItem={newPortfolio}
                ItemComponent={({ item, isExpanded }) => (
                    <PortfolioItem
                        portfolio={item}
                        isExpanded={isExpanded}
                        onEdit={handleOpenFormForEdit}
                    />
                )}
                FormComponent={({ newItem, setFormExpanded }) => (
                    <PortfolioForm
                        newPortfolio={newItem}
                        setFormExpanded={setFormExpanded}
                    />
                )}
            />

            <Box sx={{ paddingTop: '20px' }}>
                <ControlSection
                    itemsPerPage={itemsPerPage}
                    activePage={activePage}
                    totalItems={filteredPortfolios.length}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={setActivePage}
                    onExport={() => exportToCSV(portfolios, 'portfolios.csv')}
                />
            </Box>
        </Box>
    )
}

export default Portfolio