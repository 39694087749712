import { PaymentParams, PaymentReceivedFromLender, SenderParams } from 'app/models/payments-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import React, { useEffect, useState } from 'react'
import { mapEntityIdsToNames } from 'app/utils/util-functions'
import { useDispatch, useSelector } from 'react-redux'
import { columnDefs, SenderTableParams } from './sender-column-defs'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import MainTable from 'app/views/components/Table/MainTable'
import { Button, Group, Switch, Text } from '@mantine/core'
import { changeFrontingOnPayment, changeReceivedLenders } from 'app/state/ducks/payments/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import { getSenders } from 'app/state/ducks/payments/selectors'
import { useListState } from '@mantine/hooks'
import { StableKey } from 'app/models/common-types'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'

interface Props {
  payment: PaymentParams
  isDrawer?: boolean
}


export const SenderTable = ({ payment, isDrawer=false }: Props) => {
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const senders = useSelector(getSenders)
  const [fronting, setFronting] = useState(payment.payment.fronting)
  const [loading, setLoading] = useState(false);
  const [lenderReceived, lenderReceivedHandler] = useListState<PaymentReceivedFromLender>()
  const [disabled, setDisabled] = React.useState(true)

  useEffect(() => {
    lenderReceivedHandler.setState(senders.map(({ lenderId, paymentReceived }) => ({ lenderId, oldPaymentReceived: paymentReceived, newPaymentReceived: paymentReceived })))
  }, [senders])

  const changeReceived = (lenderId: StableKey, value: boolean) => {
    const newLenderReceived = [...lenderReceived]
    const index = newLenderReceived.findIndex(lender => lender.lenderId === lenderId)
    if (index < 0) {
      return
    }
    newLenderReceived[index].newPaymentReceived = value
    lenderReceivedHandler.setState(newLenderReceived)
    setDisabled(newLenderReceived.every(lender => lender.oldPaymentReceived === lender.newPaymentReceived))
  }



  const saveReceived = async () => {
    setLoading(true)
    const updatedLenders = lenderReceived.filter(lender => lender.oldPaymentReceived !== lender.newPaymentReceived).map(({ lenderId, newPaymentReceived }) => ({ lender: lenderId, paymentReceived: newPaymentReceived }))
    const response: any = await dispatch(changeReceivedLenders(payment, updatedLenders))
    setLoading(false)
    if (response.success === false) {
      ErrorNotification({
        title: 'Change Received status Failed',
        message: response.payload ?? 'Failed to change payment received status',
      }, isDrawer)
      return
    }

    SuccessNotification({
      title: 'Successfully changed payment received status',
      message: 'Statuses are updated.',
    }, isDrawer)
  }

  const changeFronting = async (value: boolean) => {
    setFronting(value)
    const response: any = await dispatch(changeFrontingOnPayment(payment, value))
    if (response.success === false) {
      ErrorNotification({
        title: 'Change Fronting status Failed',
        message: response.payload ?? 'Failed to change fronting',
      }, isDrawer)
      setFronting(!value)
      return
    }
    SuccessNotification({
      title: 'Successfully changed fronting status',
      message: 'Status is updated.',
    }, isDrawer)
  }

  function cleanDealData(
    filteredReceivers: SenderParams[],
  ): SenderTableParams[] {
    const entityIdToName = mapEntityIdsToNames(entities);

    return (
      filteredReceivers.map((lender) => {
        const lenderName = lender.lenderId?.id
          ? entityIdToName.get(cleanUUID(lender.lenderId.id)) ?? ''
          : '';
        const received = lenderReceived.find(({ lenderId }) => lenderId === lender.lenderId)?.newPaymentReceived ?? lender.paymentReceived

        return {
          lender: lenderName,
          currency: payment.payment.currency,
          amountExpected: formatNumberToCurrency(Number(lender.amount), payment.payment.currency),
          received: <Button
            disabled={payment.status !== 'Queued' || payment.submitted}
            className={`button__received_${received ? 'green' : 'pink'}`}
            onClick={() => changeReceived(lender.lenderId, !received)}
          >
            {
              received ? (
                <Text>
                  Yes
                </Text >
              ) : (
                <Text>No</Text>
              )}
          </Button >,
        }
      })

    );
  }

  return (
    <div style={{ padding: "0px 30px" }}>
      <MainTable
        minHeight={'100px'}
        tableName=''
        withPagination={false}
        csvExportEnabled={false}
        enableTopToolbar={false}
        headerBackgroundColor='#F0EEEE'
        columnDefs={columnDefs}
        data={cleanDealData(senders)}
        setRow={() => null} />
      <Group position='apart' pr="22px" pt="10px">
        <Switch
          labelPosition="right"
          label={<Text color='prime-purple' weight='600'>Fronting</Text>}
          size="lg"
          color='prime-purple'
          checked={fronting}
          onChange={() => { changeFronting(!fronting) }}
          value={fronting.toString()}
        />
        <PrimaryButton disabled={disabled} onClick={saveReceived} loading={loading}>Save</PrimaryButton>

      </Group>
    </div >
  )
}

