import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { PortfoliosAndStrategiesDrillPage } from './portfolios-and-strategies-drill-page'
import { capitalizeFirstLetter } from 'app/utils/util-functions'

export default function PortfoliosAndStrategiesPage(tab: { tab: string }) {
    return (
        <PageLayout>
            <PageTitle pageTitle={capitalizeFirstLetter(tab.tab)} />
            <PortfoliosAndStrategiesDrillPage tab={tab.tab} />
        </PageLayout>
    )
}
