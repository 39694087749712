import * as types from './types'

export const getStrategies = (payload: any) => ({
  type: types.GET_STRATEGIES,
  payload,
})

export const getStrategy = (payload: any) => ({
  type: types.GET_STRATEGY,
  payload,
})

export const addStrategy = (payload: any) => ({
  type: types.ADD_STRATEGY,
  payload,
})

export const editStrategy = (payload: any) => ({
  type: types.EDIT_STRATEGY,
  payload,
})

export const deleteStrategies = (payload: any) => ({
  type: types.DELETE_STRATEGIES,
  payload,
})

export const startLoadingStrategies = () => ({
  type: types.START_LOADING_STRATEGIES,
  payload: null,
})

export const stopLoadingStrategies = () => ({
  type: types.STOP_LOADING_STRATEGIES,
  payload: null,
})