import React, { useState } from 'react'
import { Box } from '@mantine/core'
import ControlSection from '../entities/components/control-section'
import ItemPairList from '../common/item-pair-list'
import StrategyItem from './components/strategies/strategy-item'
import StrategyForm from './components/strategies/strategy-form'
import { StrategyParams } from 'app/models/strategy-params'
import { useSelector } from 'react-redux'
import { getStrategies } from 'app/state/ducks/strategies/selectors'
import { exportToCSV } from 'app/utils/util-functions'

interface StrategyProps {
    strategyFormExpanded: boolean
    setStrategyFormExpanded: React.Dispatch<React.SetStateAction<boolean>>
    strategySearchTerm: string
    setStrategySearchTerm: React.Dispatch<React.SetStateAction<string>>
    expandedStrategyId: string | null
    setExpandedStrategyId: React.Dispatch<React.SetStateAction<string | null>>
    newStrategy: StrategyParams
    setNewStrategy: React.Dispatch<React.SetStateAction<StrategyParams>>
}

function Strategy({
    strategyFormExpanded,
    setStrategyFormExpanded,
    expandedStrategyId,
    setExpandedStrategyId,
    strategySearchTerm,
    newStrategy,
    setNewStrategy, }: StrategyProps) {
    const strategies: StrategyParams[] = useSelector(getStrategies)

    const [activePage, setActivePage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const startIdx = (activePage - 1) * itemsPerPage
    const endIdx = startIdx + itemsPerPage
    const filteredStrategies = strategies.filter((strategy) =>
        strategy.name.toLowerCase().includes(strategySearchTerm.toLowerCase())
    )
        .sort((a, b) => a.name.localeCompare(b.name))
    const currentStrategies = filteredStrategies.slice(startIdx, endIdx)
    const handleItemsPerPageChange = (value: string) => {
        setItemsPerPage(parseInt(value, 10))
        setActivePage(1)
    }

    const handleOpenFormForEdit = (strategyToEdit: StrategyParams) => {
        setStrategyFormExpanded(false)
        setNewStrategy(strategyToEdit)
        setExpandedStrategyId(strategyToEdit.id || null)
    }

    return (
        <Box sx={{ paddingTop: '20px' }}>
            {strategyFormExpanded && (
                <StrategyForm
                    newStrategy={undefined}
                    setFormExpanded={(expanded) => {
                        setStrategyFormExpanded(expanded)
                    }}
                />
            )}

            <ItemPairList<StrategyParams>
                items={currentStrategies}
                expandedItemId={expandedStrategyId}
                setExpandedItemId={setExpandedStrategyId}
                onEdit={handleOpenFormForEdit}
                newItem={newStrategy}
                ItemComponent={({ item, isExpanded }) => (
                    <StrategyItem
                        strategy={item}
                        isExpanded={isExpanded}
                        onEdit={handleOpenFormForEdit}
                    />
                )}
                FormComponent={({ newItem, setFormExpanded }) => (
                    <StrategyForm
                        newStrategy={newItem}
                        setFormExpanded={setFormExpanded}
                    />
                )}
            />

            <Box sx={{ paddingTop: '20px' }}>
                <ControlSection
                    itemsPerPage={itemsPerPage}
                    activePage={activePage}
                    totalItems={filteredStrategies.length}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={setActivePage}
                    onExport={() => exportToCSV(strategies, 'strategies.csv')}
                />
            </Box>
        </Box>
    )
}

export default Strategy