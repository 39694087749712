import { EntityParams } from "app/models/entity-params";
import { EntityProfileParams } from "app/models/entity-profile-params";

type CSVRow = Record<string, string>;
type EntityTypeOption = {
    label: string;
    value: string;
};

export type SubmissionObject = {
    id: string | null;
    entityName: string;
    entityType: string | null;
    entityProfile: string[];
    entityReference: string;
    address: string | null;
    address2: string | null;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    kycType: string;
    kycStatus: string;
    institutionType: string | null;
    countryOfIncorporation: string;
    countryOfTaxResidence: string;
    taxFormStatus: string;
    usTaxFormType: string;
    fundManager: string | null;
    fundManagerMei: string | null;
    parent: string | null;
    parentMei: string | null;
    giin: string;
    crn: string;
    naicCode: string;
    internalId: string;
    legalEntityId: string;
    mei: string;
    ein: string;
    cei: string;
    ukTreatyPassportNumber: string | null;
    status: string;
    ukTreatyPassportExpiryDate: string | null;
    entityParty: string | null;
    hasError: boolean;
    ErrorMessage: string;
    hasSuccessfulEntitySave: boolean;
    customer_token: string;
};

export function transformCSVToSubmission(
    csvRows: CSVRow[],
    entities: EntityParams[],
    entityTypeOptions: EntityTypeOption[],
    entityProfiles: EntityProfileParams[],
    transformValues: (values: SubmissionObject) => EntityParams
): EntityParams[] {
    return csvRows.map((row) => {
        const entityType = entityTypeOptions.find((type) => type.label === row["Entity Type"])?.value || null;
        const entityProfile = entityProfiles
            .filter((profile) => profile.isActive && profile.name === row["Entity Profile"])
            .map((profile) => profile?.id ?? '');

        const updatedRow = {
            id: entities.find(entity => entity.entityName === row["Entity Name"])?.id || null,
            entityName: row["Entity Name"] || "",
            entityType: entityType,
            entityProfile: entityProfile,
            entityReference: row["Entity Reference"] || "",
            address: row["Address"] || null,
            address2: row["Address 2"] || null,
            city: row["City"] || "",
            state: row["State/Region"] || "",
            zipCode: row["Zip/Postal Code"] || "",
            country: row["Country/Region"] || "",
            kycType: row["KYC Type"] || "",
            kycStatus: row["KYC Status"] || "",
            institutionType: row["Institution Type"] || null,
            countryOfIncorporation: row["Country of Incorporation"] || "",
            countryOfTaxResidence: row["Country of Tax Residence"] || "",
            taxFormStatus: row["Tax Form Status"] || "",
            usTaxFormType: row["US Tax Form Type"] || "",
            fundManager: null,
            fundManagerMei: null, // Example placeholder
            parent: null,
            parentMei: null,
            giin: row["GIIN"] || "",
            crn: row["CRN"] || "",
            naicCode: row["NAIC"] || "",
            internalId: row["Internal Entity ID"] || "",
            legalEntityId: row["LEI"] || "",
            mei: row["MEI"] || "",
            ein: row["EIN"] || "",
            cei: row["CEI"] || "",
            ukTreatyPassportNumber: null,
            status: "Inactive",
            ukTreatyPassportExpiryDate: null,
            entityParty: null,
            hasError: false,
            ErrorMessage: "",
            hasSuccessfulEntitySave: false,
            customer_token: "RIGHTPEDAL",
        };
        return transformValues(updatedRow);
    });
}
