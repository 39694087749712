import React, { useEffect, useState } from 'react';
import { Group, Stack } from '@mantine/core';
import MainTable from 'app/views/components/Table/MainTable';
import { MasterTradeParams } from 'app/models/master-trade-params';
import { columnDefs } from './trade-enty-page-columnDefs';
import { loadAllDeals } from 'app/state/ducks/deals/thunks';
import { getDeals } from 'app/state/ducks/deals/selectors';
import { useDispatch, useSelector } from 'react-redux'
import { loadEntities } from 'app/state/ducks/entities/thunks';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks';
import { getFacilities } from 'app/state/ducks/facilities/selectors';
import TradeEntryPanel from './trade-entry-panel';
import { NewSelectInput } from 'app/views/components/inputs/selects/NewSelectInput';
import { tradeViewColumns } from 'app/models/dropdown-options';
import { formatNumberToCurrency, sortData } from 'app/utils/util-functions';



interface ExpandableMantineTableProps {
    masterTrades: MasterTradeParams[];
    globalLoading: boolean;
    title: string;
}

const ExpandableMantineTable: React.FC<ExpandableMantineTableProps> = ({ masterTrades, globalLoading, title }) => {
    const dispatch = useDispatch()
    const [, setIsInactiveLoan] = React.useState(false);
    const allDeals = useSelector(getDeals)
    const allEntities = useSelector(getEntities)
    const allFacilities = useSelector(getFacilities)
    const [sortBy, setSortBy] = useState<string>('tradeDate');
    const [order, setOrder] = useState<string>('Ascending');


    const handleToggle = (isActive: boolean) => {
        setIsInactiveLoan(isActive);
    };

    useEffect(() => {
        dispatch(loadAllDeals())
        dispatch(loadEntities())
        dispatch(loadAllFacilities())
    }, [])

    const refinedMasterTrades = masterTrades.map(trade => {
        const matchingDeal = allDeals.find(deal => deal.id === trade?.dealId?.id);
        const matchingSeller = allEntities.find(entity => entity.id === trade.sellerId?.id);
        const matchingBuyer = allEntities.find(entity => entity.id === trade.buyerId?.id);
        const entityBuy = trade.myEntity === 'Buy' ? matchingBuyer : matchingSeller;
        const entitySell = trade.myEntity === 'Sell' ? matchingBuyer : matchingSeller;

        const refinedFacilities = trade.tradedFacilities.map(facility => {
            const matchingFacility = allFacilities.find(f => f.id === facility.facilityId.id) || null;

            return {
                ...facility,
                facilityDetails: matchingFacility
            };
        });

        const baseCurrency = trade.tradedFacilities?.find(
            facility => facility.facilityDetails?.baseCurrency
        )?.facilityDetails?.baseCurrency ?? 'USD';


        return {
            ...trade,
            tradedFacilities: refinedFacilities,
            tradeDetails: `${trade.tradeAssociation} - ${trade.tradeDocsType} - ${trade.tradeDocsType}`,
            entityName: entityBuy ? entityBuy.entityName : 'Unknown',
            entityMei: entityBuy ? entityBuy.mei : 'N/A',
            counterpartyName: entitySell ? entitySell.entityName : 'Unknown',
            counterpartyMei: entitySell ? entitySell.mei : 'N/A',
            dealCusip: matchingDeal ? matchingDeal.cusip : 'N/A',
            dealName: matchingDeal ? matchingDeal.dealName : 'N/A',
            tradedAmount: formatNumberToCurrency(
                trade.tradedFacilities.reduce((acc, curr) => acc + Number(curr.tradeAmount), 0),
                baseCurrency
            ),
            currentAmount: formatNumberToCurrency(trade.tradedFacilities.reduce((acc, curr) => acc + Number(curr.currentAmount), 0),
                baseCurrency),
        };
    });

    const renderDetailPanel = (row: { original: MasterTradeParams; getIsExpanded: () => boolean }) => (
        <TradeEntryPanel row={row.original} isExpanded={row.getIsExpanded()} />
    );

    const sortedMasterTrades = sortData(refinedMasterTrades, sortBy, order as 'Ascending' | 'Descending');

    return <>
        <Stack w='100%' className='sectionRemoveGap sectionBody' >
            <Group className='tradeEntryInputGroup' style={{ justifyContent: 'flex-end', marginRight: '5%', marginBottom: '-2%' }}>
                <NewSelectInput
                    wrapperType="trade"
                    label="Sort By"
                    data={tradeViewColumns}
                    w="164px"
                    h="42px"
                    placeholder="Sort By"
                    value={sortBy}
                    onChange={(value) => setSortBy(value ?? 'tradeDate')}
                />
                <NewSelectInput
                    wrapperType="trade"
                    label="Order"
                    data={['Ascending', 'Descending']}
                    w="164px"
                    h="42px"
                    placeholder="Order"
                    value={order}
                    onChange={(value) => setOrder(value ?? 'Ascending')}
                />
            </Group>

            <Stack w='100%' pt='12px' className='sectionDetailBody' style={{ padding: '40px' }}>

                {columnDefs.length != 0 ? (
                    <MainTable
                        tableName={title}
                        columnDefs={columnDefs}
                        withPagination={true}
                        data={sortedMasterTrades ?? []}
                        setRow={() => { }}
                        renderDetailPanel={({ row }) => renderDetailPanel(row)}
                        csvExportEnabled
                        enableTopToolbar={false}
                        headerBackgroundColor='#F0EEEE'
                        isLoading={globalLoading}
                        rowsPerPage={5}
                        isAccordionTable={true}
                        showBottomBoolean={false}
                        onToggle={(isActive) => handleToggle(isActive)}
                        border={true}
                        fromTradeEntry={true}
                        enableColumnOrdering={false}
                        enableColumnResizing={false}
                        enableGrouping={false}
                        enableColumnFilters={false}
                        enableColumnActions={false}
                        enableFilters={false}
                        enableSorting={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableHiding={false}
                    />) : null}
            </Stack>
        </Stack >
    </>;
};


export default ExpandableMantineTable;