import React, { useMemo } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Divider, NavLink, CSSObject } from '@mantine/core';
import { Route } from './types';

interface NavigationItemProps {
    item: Route;
    open: boolean;
    activePath: string;
    onClick: () => void;
    isExtendedMain?: boolean;
}

const baseStyles = (isActive: boolean, shouldOverrideColor: boolean, isExtendedMain: boolean, opened: boolean): { root: CSSObject; label: CSSObject } => ({
    root: {
        borderRadius: '8px',
        maxWidth: opened ? 'none' : '34px',
        backgroundColor: isActive ? '#32439A' : isExtendedMain ? '#E5DEDE' : 'transparent',
        '&[data-active]': {
            backgroundColor: '#32439A',
            borderRadius: '8px',
            ':hover': {
                backgroundColor: '#12237A',
            },
        },
    },
    label: {
        borderRadius: '8px',
        fontFamily: 'Plus Jakarta Sans',
        color: isActive || shouldOverrideColor ? '#FFFFFF' : '#45526C',
    },
});

const NavigationItem: React.FC<NavigationItemProps> = ({ item, open, activePath, onClick, isExtendedMain }) => {
    if (item.isExtended) return null;
    if (item.key === 'divider') return <Divider key={item.key} size="sm" />;

    const isActive =
        activePath.includes(item.link) &&
        !(item.key === 'tableMaintenance' && (activePath.includes('/usermanagement') || activePath.includes('/indexrateoptions') || activePath.includes('/entityprofile') || activePath.includes('/entitytype')));

    const strokeFill = activePath.includes('/tradeentry')
    
    const iconComponent = typeof item.icon === 'function' ? item.icon() : item.icon;

    const shouldOverrideColor =
        React.isValidElement(iconComponent) &&
        iconComponent.props.name === 'apps' &&
        activePath === '/usermanagement' &&
        item.key !== 'tableMaintenance';

    const activeClass = 
        isActive && strokeFill ? 'NavBarIconWhiteStroke' : 
        isActive ? 'NavBarIconWhiteFilled' : 
        undefined;

    const updatedIconComponent = useMemo(() => {
        if (React.isValidElement<{ style?: React.CSSProperties; name?: string }>(iconComponent)) {
            const color = shouldOverrideColor || (isActive && item.key !== 'tableMaintenance') ? '#FFFFFF' : '#45526C';

            return React.cloneElement(iconComponent as React.ReactElement<any>, {
                style: {
                    ...iconComponent.props.style,
                    color,
                },
                className: activeClass,
            });
        }
        return iconComponent;
    }, [iconComponent, isActive, shouldOverrideColor, item.key]);

    return (
        <Link to={item.link} onClick={onClick} style={{ width: '100%', display: 'flex' }}>
            <NavLink
                icon={updatedIconComponent}
                label={open ? item.label : undefined}
                variant="filled"
                styles={baseStyles(isActive, shouldOverrideColor, isExtendedMain ?? false, open)}
                active={isActive}
                py="md"
                my={'3px'}
            />
        </Link>
    );
};

export default NavigationItem;
