import {
    Anchor,
    Breadcrumbs,
    Group,
    Stack,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { useNavigate, useParams } from 'react-router-dom'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getFacilities,
    getFacility,
} from 'app/state/ducks/facilities/selectors'
import {
    loadAllFacilities,
    setOneFacility,
} from 'app/state/ducks/facilities/thunks'
import { loadAllLoans, loadLockedLoans } from 'app/state/ducks/loans/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadLendersPositionsByFacility } from 'app/state/ducks/lenders-positions/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { ContractType } from 'app/models/common-types'
import { FacilityParams } from 'app/models/facility-params'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { loadAllLetterOfCredits } from 'app/state/ducks/letters-of-credits/thunks'
import { loadIndexRateOptions } from 'app/state/ducks/index-rate-options/thunks'
import { loadAllLoanRollovers } from 'app/state/ducks/loan-rollovers/thunks'
import { loadAllLoanIncreases } from 'app/state/ducks/loan-increases/thunks'
import { loadAllLoanConversions } from 'app/state/ducks/loan-conversions/thunks'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import FacilityHeader from '../facility-details/facility-header'
import FacilityActions from '../facility-details/facility-actions'
import FacilityLocContent from '../../letter-of-credit/facility-loc/locs-page'
import LoansPage from '../../loans/facility-loans/loans-page'
import FacilityPage from './facility-page'


interface props {
    tab: string
}

export function FacilityDrillPage({ tab }: props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { facilityId, status } = useParams()
    const facility = useSelector(getFacility)

    const allFacilities: FacilityParams[] = useSelector(getFacilities)
    const facilityAmendments: FacilityParams[] = useSelector(
        getFacilityAmendments
    )

    useEffect(() => {
        if (!facilityId) {
            return
        }
        if (allFacilities) {
            const selectedFacility = allFacilities.find(
                facility => facility.id === facilityId
            )
            if (selectedFacility) {
                selectedFacility.amendment = false
                dispatch(setOneFacility(selectedFacility))
            }
        }
        if (facilityAmendments) {
            const amended_facility = facilityAmendments.find(
                amendment => amendment.id === facilityId
            )
            if (amended_facility) {
                amended_facility.amendment = true
                dispatch(setOneFacility(amended_facility))
            }
        }
    }, [facilityId, facilityAmendments, allFacilities])

    useEffect(() => {
        if (!facilityId) {
            return
        }
        dispatch(loadAllLoans(facilityId))
        dispatch(loadLockedLoans(facilityId))
        dispatch(loadAllLetterOfCredits(facilityId))
        dispatch(loadLendersPositionsByFacility(facilityId))
        dispatch(loadEntities())
        dispatch(loadAllFacilities())
        dispatch(loadWireInstructions())
        dispatch(loadAllDeals())
        dispatch(loadAllFacilityAmendments())
        dispatch(loadIndexRateOptions())
        dispatch(loadAllLoanRollovers())
        dispatch(loadAllLoanIncreases())
        dispatch(loadAllLoanConversions())
        dispatch(loadNoteByContract(facilityId, ContractType.Facilities))
    }, [facilityId])


    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element } } = {
        facilityDetails: { route: `/dealmanagement/facilities/${facilityId}/${status}`, label: 'FACILITY', active: tab === 'facilityDetails' ? true : false, pageRender: <FacilityPage facility={facility} /> },
        facilityDetailsLoans: { route: `/dealmanagement/facilities/${facilityId}/${status}/loans`, label: 'LOANS', active: tab === 'facilityDetailsLoans' ? true : false, pageRender: <LoansPage /> },
        facilityDetailsLoCs: { route: `/dealmanagement/facilities/${facilityId}/${status}/locs`, label: 'LETTERS OF CREDIT', active: tab === 'facilityDetailsLoCs' ? true : false, pageRender: <FacilityLocContent /> },
    }

    const items = [
        { title: 'Deal Management', href: '/dealmanagement' },
        { title: 'Facilities', href: '/dealmanagement/facilities' },
    ].map((item, index) => (
        <Anchor
            onClick={item.href ? () => navigate(item.href) : () => null}
            key={index}
            color="#374151"
            fw={500}
            fz="14px"
        >
            {item.title}
        </Anchor>
    ))

    return (
        <Stack w="100%">
            <Breadcrumbs
                separator={<IonIcon name="chevron-forward-outline" />}
            >
                {items}
            </Breadcrumbs>
            {facility ? <FacilityHeader facility={facility} showStatusPill={true} /> : null}
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    {facility && <FacilityActions primaryInput={true} row={facility} withRedirection extended />}
                </Group>
            </Group>
            <Group noWrap w='100%' mt='10px'>
                {tabs[tab].pageRender}
            </Group>
        </Stack>
    )
}