import React from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { TradeManagementDrillPage } from './trade-management-drill-page'


export function TradeManagementLandingPage(tab: { tab: string }) {
  return (
    <PageLayout>
      <PageTitle pageTitle='Trade Entry' />
      <TradeManagementDrillPage tab={tab.tab} />
    </PageLayout>
  )
}
