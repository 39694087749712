import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getFacilityAmendments,
  editFacilityAmendment,
  getFacilityAmendment,
  submitFacilityAmendment,
  rejectFacilityAmendment,
  approveFacilityAmendment,
  deleteFacilityAmendment,
  addFacilityAmendment,
  changeFacilityFees,
} from './actions'
import { DraftFacilityParams, FacilityParams } from 'app/models/facility-params'
import { TerminationParams } from 'app/models/termination-params'
import { ChangeFeeData, Fees } from 'app/models/fees-params'
import { StableKey } from 'app/models/common-types'
import { ChangeAmortizationSchedulerData } from 'app/models/amortitation-schedule'

export const saveFacilityAmendment =
  (savedFacility: DraftFacilityParams, amended_facility?: any, facility_fees?: Fees[]) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (savedFacility.accrualFee) {
      savedFacility.accrualFee.map((fee: any) => {
        if (fee.dayBasis === '') fee.dayBasis = null
      })
    }

    const updated_savedFacility: DraftFacilityParams = {
      ...savedFacility,
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }
    if (amended_facility) {
      updated_savedFacility.amendmentDate = updated_savedFacility.amendmentDate ?? amended_facility.amendmentDate
      updated_savedFacility.amendmentType = updated_savedFacility.amendmentType ?? amended_facility.amendmentType
      return client
        .put(
          `${apiServerUrl}/api/amendment-draft-facilities`,
          updated_savedFacility,
        )
        .then((e: any) => {
          dispatch(editFacilityAmendment(updated_savedFacility))
          return { payload: e.data, success: true }
        })
        .catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    } else {
      updated_savedFacility.amendmentFees = facility_fees ?? []
      return client
        .post(
          `${apiServerUrl}/api/approved-facilities/amendment`,
          updated_savedFacility,
        )
        .then((e: any) => {
          dispatch(addFacilityAmendment(e.data))
          return { payload: e.data, success: true }
        })
        .catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    }
  }

export const saveFacilityTermination =
  (terminationData: TerminationParams, facilityAmendment?: FacilityParams) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (facilityAmendment) {
      facilityAmendment.amendmentDate = terminationData.amendmentDate
      return client
        .put(
          `${apiServerUrl}/api/amendment-draft-facilities`,
          facilityAmendment,
        )
        .then(data => {
          dispatch(editFacilityAmendment(facilityAmendment))
          return { payload: data.data, success: true }
        })
        .catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    } else {
      return client
        .post(
          `${apiServerUrl}/api/approved-facilities/terminate`,
          terminationData,
        )
        .then(data => {
          dispatch(addFacilityAmendment(data))
          return { payload: data.data, success: true }
        })
        .catch(e => {
          return { payload: e.response.data.error, success: false }
        })
    }
  }

const loadDraftFacilityAmendments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/amendment-draft-facilities`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadSubmittedFacilityAmendments = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/amendment-submitted-facilities`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllFacilityAmendments = () => async (dispatch: Dispatch) => {
  const draftFacilities = await loadDraftFacilityAmendments()
  const submittedFacilities = await loadSubmittedFacilityAmendments()
  const subFacilities = submittedFacilities.map(
    (a: {
      facility: any
      amendmentType: string
      amendmentDate: string
      amendmentFees: any[]
      amendmentLenders: any[]
      amendmentAmortization: any
    }) => ({
      ...a.facility,
      amendmentType: a.amendmentType,
      amendmentDate: a.amendmentDate,
      amendmentFees: a.amendmentFees,
      amendmentLenders: a.amendmentLenders,
      amendmentAmortization: a.amendmentAmortization,
      status: 'Submitted',
    }),
  )
  const allFacilities = draftFacilities
    .map((facility: any) => ({ ...facility, status: 'Draft' }))
    .concat(subFacilities)
  return dispatch(getFacilityAmendments(allFacilities))
}

export const loadOneFacilityAmendment =
  (facilityId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const statusLower = status.toLowerCase()
    return client
      .get(
        `${apiServerUrl}/api/amendment-${statusLower}-facilities/one?id=${facilityId}`,
      )
      .then(facilities => {
        if (statusLower === 'submitted') {
          return dispatch(
            getFacilityAmendment({
              ...facilities.data.facility,
              amendmentType: facilities.data.amendemntType,
              amendmentDate: facilities.data.amendemntDate,
              status: status,
            }),
          )
        }
        return dispatch(
          getFacilityAmendment({ ...facilities.data, status: status }),
        )
      })
      .catch(() => {
        return null
      })
  }

export const loadNullFacilityAmendment = () => async (dispatch: Dispatch) => {
  return dispatch(getFacilityAmendment(null))
}

export const submitForApprovalAmendment =
  (facility: FacilityParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amendment-draft-facilities/submit`, {
        id: facility.id,
      })
      .then(response => {
        dispatch(submitFacilityAmendment(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const rejectSubmittedFacilityAmendment =
  (facility: FacilityParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amendment-submitted-facilities/reject`, {
        id: facility.id,
      })
      .then(response => {
        dispatch(rejectFacilityAmendment(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const approveSubmittedFacilityAmendment =
  (facility: FacilityParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amendment-submitted-facilities/approve`, {
        id: facility.id,
      })
      .then(response => {
        dispatch(approveFacilityAmendment(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const deleteDraftFacilityAmendment =
  (ids: string[]) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/amendment-draft-facilities?ids=${ids}`)
      .then(response => {
        return dispatch(deleteFacilityAmendment(response.data))
      })
      .catch(e => {
        console.log('THUNK ERROR', e)
      })
  }

export const changeAllFeesForFacilityAmendments =
  (fees: ChangeFeeData[], facilityId: StableKey) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/amendment-draft-facilities/change-fees`, {
        feesData: fees.map(fee => ({
          feeType: fee.feeType,
          feeRate: fee.feeRate,
          frequency: fee.frequency,
          dayBasis: fee.dayBasis,
          firstPaymentDate: fee.firstPaymentDate,
          id: fee.id,
          oneTimeFeeAmount: fee.oneTimeFeeAmount,
        })),
        facilityId: facilityId,
      })
      .then((response: any) => {
        dispatch(changeFacilityFees(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }

export const changeAmortizationForFacilityAmendments =
  (
    amortizationSchedulerData: ChangeAmortizationSchedulerData,
    facilityId: StableKey,
  ) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(
        `${apiServerUrl}/api/amendment-draft-facilities/change-amortization`,
        {
          amortizationSchedulerData: {
            ...amortizationSchedulerData,
          },
          facilityId: facilityId,
        },
      )
      .then((response: any) => {
        dispatch(changeFacilityFees(response.data))
        return { payload: response.data, success: true }
      })
      .catch(e => {
        return { payload: e.response.data.error, success: false }
      })
  }
