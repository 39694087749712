import React from 'react';
import { Stack } from '@mantine/core';
import { MasterTradeParams } from 'app/models/master-trade-params';
import MainTable from 'app/views/components/Table/MainTable';
import { columnDefs } from './trade-entry-panel-columnDefs';
import { SubTradeParams } from 'app/models/sub-trade-params';
import { useSelector } from 'react-redux';
import { getSubTrades } from 'app/state/ducks/sub-trades/selectors';
import { getEntities } from 'app/state/ducks/entities/selectors';
import { getFacilities } from 'app/state/ducks/facilities/selectors';
import { getBusinessDate } from 'app/state/ducks/business-date/selectors';
import moment from 'moment';

interface DetailPanelProps {
  row: MasterTradeParams;
  isExpanded: boolean;
}

const TradeEntryPanel: React.FC<DetailPanelProps> = ({ row }) => {
  const subTrades: SubTradeParams[] = useSelector(getSubTrades)
  const allEntities = useSelector(getEntities)
  const allFacilities = useSelector(getFacilities)
  const businessDate = useSelector(getBusinessDate)

  const refinedSubTrades = subTrades.filter(subTrade => subTrade.masterTradeId.id === row.id).map(trade => {
    const matchingSeller = allEntities.find(entity => entity.id === trade.sellerId?.id);
    const matchingBuyer = allEntities.find(entity => entity.id === trade.buyerId?.id);

    const refinedFacilities = trade.tradedFacilities.map(facility => {
      const matchingFacility = allFacilities.find(f => f.id === facility.facilityId.id) || null;

      return {
        ...facility,
        facilityDetails: matchingFacility
      };
    });

    return {
      ...trade,
      sellerEntity: matchingSeller || null,
      buyerEntity: matchingBuyer || null,
      tradedFacilities: refinedFacilities,
      masterTrade: row,
      tPlus: moment(businessDate).diff(moment(row.tradeDate), 'days')
    };
  });

  return (
    <>
      {refinedSubTrades.length != 0 && (
        <Stack w="100%" className="sectionDetailTradeBody noHeader">
          <Stack w='100%' className='sectionRemoveGap sectionBody'>
            <Stack w='100%' className='sectionDetailTradeBody' style={{ padding: '10px' }}>
              <MainTable
                tableName={'AA'}
                columnDefs={columnDefs}
                withPagination={false}
                data={refinedSubTrades ?? []}
                setRow={() => { }}
                enableTopToolbar={false}
                headerBackgroundColor='#F0EEEE'
                enableColumnOrdering={false}
                enableColumnResizing={false}
                enableGrouping={false}
                enableColumnFilters={false}
                enableColumnActions={false}
                enableFilters={false}
                enableSorting={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                border={true}
                fromTradeEntry={false}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default TradeEntryPanel;
