import {
    Group,
    Stack,
    TextInput,
} from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import PrimaryInput from 'app/views/components/buttons/PrimaryInput'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Portfolio from './portfolio'
import Strategy from './strategy'
import { loadAllPortfolios } from 'app/state/ducks/portfolios/thunks'
import { loadAllStrategies } from 'app/state/ducks/strategies/thunks'
import { PortfolioParams } from 'app/models/portfolio-params'
import { StrategyParams } from 'app/models/strategy-params'
import { getPortfolioLoader } from 'app/state/ducks/portfolios/selectors'
import { getStrategyLoader } from 'app/state/ducks/strategies/selectors'
import { CustomLoaderPage } from 'app/views/components/loader/loader-page'
import { IconSearch } from '@tabler/icons-react'


interface props {
    tab: string
}

export function PortfoliosAndStrategiesDrillPage({ tab }: props) {
    const dispatch = useDispatch()
    const [formExpanded, setFormExpanded] = useState(false)
    const [strategyFormExpanded, setStrategyFormExpanded] = useState(false)
    const [expandedPortfolioId, setExpandedPortfolioId] = useState<string | null>(null)
    const [expandedStrategyId, setExpandedStrategyId] = useState<string | null>(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [strategySearchTerm, setStrategySearchTerm] = useState('')
    const initialNewPortfolio: PortfolioParams = {
        name: '',
        code: '',
        isActive: false
    }

    const initialNewStrategy: StrategyParams = {
        name: '',
        code: '',
        isActive: false
    }

    const [newPortfolio, setNewPortfolio] = useState(initialNewPortfolio)
    const [newStrategy, setNewStrategy] = useState(initialNewStrategy)
    const portfolioLoader = useSelector(getPortfolioLoader)
    const strategyLoader = useSelector(getStrategyLoader)
    const [globalLoading, setGlobalLoading] = useState(false)

    useEffect(() => {
        setGlobalLoading(portfolioLoader || strategyLoader)
    }, [portfolioLoader, strategyLoader])


    useEffect(() => {
        dispatch(loadAllPortfolios())
        dispatch(loadAllStrategies())
    }, [])

    const handleOpenFormForCreate = () => {
        setExpandedPortfolioId(null)
        setNewPortfolio(initialNewPortfolio)
        setFormExpanded(true)
    }

    const handleOpenFormForStrategyCreate = () => {
        setExpandedStrategyId(null);
        setNewStrategy(initialNewStrategy);
        setStrategyFormExpanded(true);
    };


    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; } } = {
        portfolio: {
            route: `/portfolioandstrategy/portfolio`,
            label: 'PORTFOLIO',
            active: tab === 'portfolio' ? true : false,
            pageRender: (
                <Portfolio
                    formExpanded={formExpanded}
                    setFormExpanded={setFormExpanded}
                    expandedPortfolioId={expandedPortfolioId}
                    setExpandedPortfolioId={setExpandedPortfolioId}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    newPortfolio={newPortfolio}
                    setNewPortfolio={setNewPortfolio}
                />
            ),
        },
        strategy: {
            route: `/portfolioandstrategy/strategy`,
            label: 'STRATEGY',
            active: tab === 'strategy' ? true : false,
            pageRender: (
                <Strategy
                    strategyFormExpanded={strategyFormExpanded}
                    setStrategyFormExpanded={setStrategyFormExpanded}
                    expandedStrategyId={expandedStrategyId}
                    setExpandedStrategyId={setExpandedStrategyId}
                    strategySearchTerm={strategySearchTerm}
                    setStrategySearchTerm={setStrategySearchTerm}
                    newStrategy={newStrategy}
                    setNewStrategy={setNewStrategy}
                />
            ),
        },
    }

    return (
        <Stack w='100%'>
            <Group w='100%' position='apart' className='tabsGapsActivity'>
                <Group position='right'>
                    <SegmentControlUpdated tabs={tabs} />
                </Group>
                <Group noWrap>
                    <TextInput
                        placeholder='Search...'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                        className='search-input'
                        icon={<IconSearch size={16} />}
                    />
                    <PrimaryInput 
                      onClick={tab === 'portfolio' ? handleOpenFormForCreate : handleOpenFormForStrategyCreate} 
                      text={tab === 'portfolio' ? 'Create Portfolio' : 'Create Strategy'} 
                    />
                </Group>
            </Group>
            {globalLoading ? <CustomLoaderPage /> :
                tabs[tab].pageRender}
        </Stack>
    )
}
