import { Group, Stack, Text, Divider } from '@mantine/core'
import { IndexRateOptionParams } from 'app/models/index-rate-options-params'
import { InterestRateParams } from 'app/models/interest-rate'
import { getInterestRatess } from 'app/state/ducks/interest-rates/selectors'
import { loadInterestByIndex } from 'app/state/ducks/interest-rates/thunks'
import CustomDatePicker from 'app/views/components/date-picker/date-picker-logic'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { stringToDate } from 'app/utils/util-functions'
import IonIcon from '@reacticons/ionicons'
import DailyRatesTable from './daily-rates-table'
import '../../../../styles/components/daily-rates.css'


interface Props {
  index?: IndexRateOptionParams
  closeDailyRates: () => void
}

export function DailyRates({ index, closeDailyRates }: Props) {
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [tableData, setTableData] = useState<InterestRateParams[]>([])
  const dailyRates: InterestRateParams[] = useSelector(getInterestRatess)

  useEffect(() => {
    if (!index) {
      return
    }
    dispatch(loadInterestByIndex(index))
  }, [index])

  useEffect(() => {
    if (!dailyRates) return
    setTableData(
      dailyRates
        .filter(
          item =>
            (startDate ? stringToDate(item.effectiveDate) >= startDate : true) &&
            (endDate ? stringToDate(item.effectiveDate) <= endDate : true)
        )
        .sort((a, b) => (a.effectiveDate > b.effectiveDate ? 1 : -1))
    )
  }, [dailyRates, startDate, endDate])

  const indexType = index?.indexType || '';

  return (
    <Stack className='daily-rates-container'>
      <Group position='apart' w='100%' mb='12px'>
        <Text className='daily-rates-title'>{index?.indexOption ?? 'CAOO'}</Text>
        <IonIcon className='drawerCloseButton' name='close-circle' onClick={closeDailyRates} />
      </Group>
      <Group noWrap>
        <CustomDatePicker
          label={'Start Date'}
          date={startDate as any}
          setDate={setStartDate}
          holidayCalendars={[]}
          styles={{
            borderRadius: '0px',
            labelStyles: {
              fontSize: '16px',
              fontWeight: 400,
              marginBottom: '6px',
              color: ' #222223',
            }
          }}
          clearable
          placeholder='Select Date'
        />
        <CustomDatePicker
          label={'End Date'}
          date={endDate as any}
          setDate={setEndDate}
          holidayCalendars={[]}
          styles={{
            borderRadius: '0px',
            labelStyles: {
              fontSize: '16px',
              fontWeight: 400,
              marginBottom: '6px',
              color: ' #222223',
            }
          }}
          clearable
          placeholder='Select Date'
        />
      </Group>
      <Divider my='lg' />
      {index && indexType && <DailyRatesTable data={tableData} indexType={index?.indexType || ''} />}
    </Stack>
  )
}
