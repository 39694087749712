import React from 'react'
import { Box } from '@mantine/core'
import { ListItem } from './item-pair-list'

interface ItemPairProps<T extends ListItem> {
    item1: T
    isExpanded1: boolean
    onEdit: (item: T) => void
    setExpandedId: React.Dispatch<React.SetStateAction<string | null>>
    newItem1: T
    item2?: T
    isExpanded2: boolean
    newItem2: T
    ItemComponent: React.FC<{
        item: T
        isExpanded: boolean
        onEdit: (item: T) => void
    }>
    FormComponent: React.FC<{
        newItem: T
        setFormExpanded: () => void
    }>
}

const ItemPair = <T extends ListItem>({
    item1,
    item2,
    isExpanded1,
    isExpanded2,
    onEdit,
    setExpandedId,
    newItem1,
    newItem2,
    ItemComponent,
    FormComponent,
}: ItemPairProps<T>) => {
    return (
        <>
            <ItemComponent
                item={item1}
                isExpanded={isExpanded1}
                onEdit={() => onEdit(item1)}
            />
            {item2 && (
                <ItemComponent
                    item={item2}
                    isExpanded={isExpanded2}
                    onEdit={() => onEdit(item2)}
                />
            )}
            {(isExpanded1 || isExpanded2) && (
                <Box mt="-10px" mb="-10px" sx={{ gridColumn: 'span 2' }}>
                    <FormComponent
                        newItem={isExpanded1 ? newItem1 : newItem2}
                        setFormExpanded={() => setExpandedId(null)}
                    />
                </Box>
            )}
        </>
    )
}

export default ItemPair