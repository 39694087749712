import {
    Group,
    Stack,
    Title,
} from '@mantine/core'
import { DealParams } from 'app/models/deal-params'
import StatusPill from '../../common/pill-status'


interface props {
    deal: DealParams
    showStatusPill?: boolean
}

export default function DealHeader({ deal, showStatusPill=true }: props) {
    return (
        <Stack className="detailsHeader">
            <Group noWrap>
                <Title className='detailsTitle'>{deal?.dealName ?? ''}</Title>
                {showStatusPill &&
                    <StatusPill
                        updated={true}
                        state={deal?.status ?? 'Draft'}
                        additionalState={deal?.amendmentType ?? ''}
                    />
                }
            </Group>
        </Stack>
    )
}