import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getStrategies,
  getStrategy,
  addStrategy,
  editStrategy,
  deleteStrategies,
  startLoadingStrategies,
  stopLoadingStrategies,
} from 'app/state/ducks/strategies/actions'
import { StrategyParams } from 'app/models/strategy-params'

export const saveStrategy =
  (savedStrategy: StrategyParams, strategy?: string) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (strategy) {
      savedStrategy.id = strategy
      return client
        .put(`${apiServerUrl}/api/strategies`, savedStrategy)
        .then(strategy => {
          dispatch(editStrategy(strategy))
          return { success: true, payload: strategy }
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/strategies`, savedStrategy)
        .then(strategy => {
          dispatch(addStrategy(strategy))
          return { success: true, payload: strategy }
        })
        .catch(error => {
          return { success: false, payload: error.response.data.error }
        })
    }
  }

export const loadAllStrategies = () => async (dispatch: Dispatch) => {
  dispatch(startLoadingStrategies())
  const apiServerUrl = config.backendUrl
  try {
    const response = await client.get(`${apiServerUrl}/api/strategies`)
    const strategies = response.data
    dispatch(getStrategies(strategies))
  } catch (e) {
    console.log(e)
    dispatch(getStrategies([]))
  } finally {
    dispatch(stopLoadingStrategies())
  }
}

export const loadOneStrategy =
  (strategyId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/strategies/one?id=${strategyId}`)
      .then(strategy => {
        return dispatch(
          getStrategy({ ...strategy.data, status: status }),
        )
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const removeStrategies =
  (strategy: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/strategies?ids[]=${strategy}`)
      .then(strategies => {
        dispatch(deleteStrategies(strategies.data))
        return { success: true, payload: strategies.data }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
