import {
  Anchor,
  Breadcrumbs,
  Group,
  Stack,
} from '@mantine/core'
import TextInput from 'app/views/components/inputs/TextInput'
import IonIcon from '@reacticons/ionicons'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { DealParams } from 'app/models/deal-params'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { getDealAmendments } from 'app/state/ducks/deal-amendments/selectors'
import { loadAllDealAmendments } from 'app/state/ducks/deal-amendments/thunks'
import { loadAllDeals, setOneDeal } from 'app/state/ducks/deals/thunks'
import DealHeader from '../deals/deal-details/deal-header'
import DealDetailSidebar from '../deals/deal-details/deal-detail-sidebar'
import { pricingGridMarginOrFeeTypeOptions, pricingGridTypeOptions } from 'app/models/dropdown-options'
import { getLabelByValue } from 'app/utils/util-functions'


export default function PricingGridContentPage(): JSX.Element {
  const navigate = useNavigate()
  const { dealId, status } = useParams()

  const dispatch = useDispatch()
  const deal: DealParams | undefined = useSelector(getDeal)
  const allDeals: DealParams[] = useSelector(getDeals)
  const dealAmendments: DealParams[] = useSelector(getDealAmendments)

  useEffect(() => {
    if (allDeals) {
      const selectedDeal = allDeals.find(deal => deal.id === dealId)
      if (selectedDeal) {
        selectedDeal.amendment = false
        dispatch(setOneDeal(selectedDeal))
      }
    }
    if (dealAmendments) {
      const amended_deal = dealAmendments.find(
        amendment => amendment.id === dealId
      )
      if (amended_deal) {
        amended_deal.amendment = true
        dispatch(setOneDeal(amended_deal))
      }
    }
  }, [allDeals, dealId, dealAmendments])

  useEffect(() => {
    dispatch(loadAllDeals())
    dispatch(loadEntities())
  }, [dealId])

  useEffect(() => {
    dispatch(loadAllFacilities())
    dispatch(loadAllDealAmendments())
  }, [])

  const items = [
    { title: 'Deal Management', href: '/dealmanagement' },
    { title: 'Deal', href: "/dealmanagement/deal/" + dealId + "/" + status },
  ].map((item, index) => (
    <Anchor
      onClick={() => navigate(item.href)}
      key={'deal-page-breadcrum' + index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  const pricingGridItems = () => {
    if (!deal || !deal.pricingGrid) {
      return []
    }
    const longestLevelsLength = deal.pricingGrid.reduce((maxLength, item) => {
      return Math.max(maxLength, item.levels.length);
    }, 0);
    const levelLength = longestLevelsLength > 6 ? longestLevelsLength : 6
    const pricingGrid = deal.pricingGrid.map((gridItem, index) => {
      const levels = gridItem.levels.length
      const emptyLevels = Array.from({ length: (levelLength - levels) })
      return (
        <Stack w="100%" key={'pricing-grid-item-' + index}>
          <Group w="100%" noWrap>
            <Group w="100%" noWrap>
              <TextInput
                w="100%"
                readOnly
                label="Type"
                value={getLabelByValue(gridItem.pricingGridType ?? '', pricingGridTypeOptions)}
              />
            </Group>
            <Group w="100%" noWrap>
              <TextInput
                w="100%"
                readOnly
                label="Margin/Fee"
                value={getLabelByValue(gridItem.marginOrFee ?? '',
                  pricingGridMarginOrFeeTypeOptions)}
              />
            </Group>
            {gridItem.levels.map((level, index) => {
              const label = "Level " + Number(index + 1).toString()
              return (
                <Group w="100%" noWrap key={'pricing-grid-level-' + index}>
                  <TextInput
                    w="100%"
                    readOnly
                    label={label}
                    value={level}
                  />
                </Group>
              )
            })}
            {emptyLevels.map((_, index) => {
              return (
                <Group w="100%" noWrap key={'pricing-grid-empty-levels-' + index}></Group>
              )
            })}
          </Group>
        </Stack>
      )
    })
    return pricingGrid
  }


  return (
    <Stack>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      {deal ? <DealHeader deal={deal} showStatusPill={false} /> : null}
      <Group h="100%">
      </Group>

      <Group noWrap position="apart" align="flex-start" h="100vh">
        <Stack className="data-header-part">
          <Stack w="100%">
            {pricingGridItems()}
          </Stack>
        </Stack>

        {deal ? <DealDetailSidebar deal={deal} /> : null}
      </Group>
    </Stack>
  )
}
