import { ScrollArea, Table, Text } from '@mantine/core'
import { InterestRateParams, TermRates } from 'app/models/interest-rate'
import '../../../../styles/components/daily-rates.css'

interface Props {
  data: InterestRateParams[]
  indexType: string
}

const DailyRatesTable = ({ data, indexType }: Props) => {
    const getColumns = (indexType: string) => {
        const columnMappings: { [key: string]: { label: string; key: string }[] } = {
          'TermIndex': [
            { label: 'Overnight', key: 'overnight' },
            { label: 'One Week', key: 'oneWeek' },
            { label: 'Two Weeks', key: 'twoWeeks' },
            { label: 'Three Weeks', key: 'threeWeeks' },
            { label: 'One Month', key: 'oneMonth' },
            { label: 'Two Months', key: 'twoMonths' },
            { label: 'Three Months', key: 'threeMonths' },
            { label: 'Four Months', key: 'fourMonths' },
            { label: 'Five Months', key: 'fiveMonths' },
            { label: 'Six Months', key: 'sixMonths' },
            { label: 'Seven Months', key: 'sevenMonths' },
            { label: 'Eight Months', key: 'eightMonths' },
            { label: 'Nine Months', key: 'nineMonths' },
            { label: 'Ten Months', key: 'tenMonths' },
            { label: 'Eleven Months', key: 'elevenMonths' },
            { label: 'One Year', key: 'oneYear' },
          ],
          'FloatingIndex': [
            { label: 'Rate Value', key: 'floatingRate' },
          ],
          'OvernightIndex': [
            { label: 'Rate Value', key: 'overnightRate' },
          ]
        };
      
        return columnMappings[indexType] || [];
      };

      const getRateValue = (item: InterestRateParams, col: { key: string }, indexType: string) => {
        if (indexType === 'TermIndex') {
          return item.termRates?.[col.key as keyof TermRates] ?? '';
        }
        return item[col.key as keyof InterestRateParams] ?? '';
      };

  const columns = getColumns(indexType)

  return (
    <ScrollArea className='daily-rates-table-scrollarea'>
      <div className='daily-rates-table-wrapper'>
        <Table>
          <thead>
            <tr className='daily-rates-thead-tr'>
              <th className='daily-rates-th'><Text className='daily-rates-th-text'>Effective Date</Text></th>
              {columns.map((col, idx) => (
                <th className='daily-rates-th' key={idx}><Text className='daily-rates-th-text'>{col.label}</Text></th>
              ))}
            </tr>
          </thead>
          <tbody className='daily-rates-tbody'>
            {data.map((item, idx) => (
              <tr key={idx} className='daily-rates-tbody-tr'>
                <td><Text className='daily-rates-td-text'>{item.effectiveDate}</Text></td>
                {columns.map((col, idx) => (
                  <td key={idx}><Text className='daily-rates-td-text'>{String(getRateValue(item, col, indexType))}</Text></td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </ScrollArea>
  );
}

export default DailyRatesTable