import IonIcon from '@reacticons/ionicons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import {
  Anchor,
  Breadcrumbs,
  Group,
  Stack,
  Text
} from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import { ContractType } from 'app/models/common-types'
import {
  loadOneFacility,
  setOneFacility,
} from 'app/state/ducks/facilities/thunks'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import {
  loadSchedulerAmortizationByFacility,
  recalculateAmortizationSchedule
} from 'app/state/ducks/amortization/thunks'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import SegmentControlUpdated from '../segments/segment-control-updated'
import FacilityHeader from 'app/views/pages/deals-management/facilities/facility-details/facility-header'
import { frequencyOptions } from 'app/models/dropdown-options'
import { AmortizationScheduleParams } from 'app/models/amortitation-schedule'
import { getFacilitySchedulerAmortization } from 'app/state/ducks/amortization/selectors'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import NotesAndAttachment from '../notes-and-attachemnt/notes-and-attachment-full'
import { ErrorNotification, SuccessNotification } from '../notifications/notification'
import { AmortizationScheduleHeaderActions } from './amortization-schedule-header-actions'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import AmortizationScheduleDetails from './amortization-schedule-details'
import { NewTextInput } from '../inputs/NewTextInput'


interface props {
  tab: string
}

export function AmortizationScheduleDrillPage({ tab }: props) {

  const { facilityId, status } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const facility = useSelector(getFacility)
  const facilityAmendments = useSelector(getFacilityAmendments)
  const deals = useSelector(getDeals)
  const facilityAmortizationScheduler: AmortizationScheduleParams = useSelector(
    getFacilitySchedulerAmortization
  )
  const [deal, setDeal] = useState<DealParams | undefined>(undefined)

  const [amortizationSchedule, setAmortizationSchedule] = useState<AmortizationScheduleParams>()
  const [isCalculationInSync, setIsCalculationInSync] = useState<boolean>(false)

  const checkAvailablePaymentSum = () => {
    const availablePaymentSum = amortizationSchedule?.scheduler
      ?.filter(scheduler => scheduler?.status !== 'Paid')
      .reduce((sum, scheduler) => sum + Number(scheduler?.paymentAmount), 0)
    setIsCalculationInSync(
      Number(facility?.amount ?? 0) === availablePaymentSum
    )
  }


  useEffect(() => {
    if (facilityAmendments && facilityAmendments.length > 0) {
      const amendment = facilityAmendments.find(
        (amendment: any) => amendment.id === facilityId
      )
      if (amendment) {
        dispatch(setOneFacility(amendment))
      }
    }
  }, [facilityAmendments, facilityId])

  useEffect(() => {
    checkAvailablePaymentSum()
  }, [facility, amortizationSchedule])

  useEffect(() => {
    if (facility && facility.amendmentAmortization) {
      setAmortizationSchedule(facility.amendmentAmortization)
    } else {
      setAmortizationSchedule(facilityAmortizationScheduler)
    }
  }, [facilityAmortizationScheduler, facility])

  useEffect(() => {
    dispatch(loadEntities())
    dispatch(loadContacts())
    dispatch(loadWireInstructions())
    dispatch(loadAllDeals())
    dispatch(loadAllFacilityAmendments())
  }, [])

  useEffect(() => {
    if (!facility || !deals) {
      return
    }
    const selectedDeal = deals.find(
      (deal: DealParams) => deal.id === facility.dealId.id
    )
    if (!selectedDeal) {
      return
    }
    setDeal(selectedDeal)
  }, [facility, deals])

  useEffect(() => {
    if (!facilityId) {
      return
    }
    dispatch(loadNoteByContract(facilityId, ContractType.Facilities))
    dispatch(loadOneFacility(facilityId ?? '', status ?? ''))
  }, [facilityId, status])

  useEffect(() => {
    dispatch(loadSchedulerAmortizationByFacility(facilityId ?? ''))
  }, [facilityId])

  const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; } } = {
    amortizationScheduleCurrent: {
      route: `/dealmanagement/facilities/${facilityId}/${status}/amortization-schedule`,
      label: 'CURRENT',
      active: tab === 'amortizationScheduleCurrent' ? true : false,
    },
    amortizationScheduleHistory: {
      route: `/dealmanagement/facilities/${facilityId}/${status}/amortization-schedule/history`,
      label: 'HISTORY',
      active: tab === 'amortizationScheduleHistory' ? true : false,
    },
  }

  const items = [
    { title: 'Deal Management', action: () => navigate('/dealmanagement') },
    {
      title: 'Facilities',
      action: () => navigate('/dealmanagement/facilities'),
    },
    {
      title: facility?.name ?? '',
      action: () =>
        navigate(
          `/dealmanagement/facilities/${facilityId}/${status}`
        ),
    },
  ].map((item, index) => (
    <Anchor
      onClick={() => item.action()}
      key={index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  const recalculate = async () => {
    const result = (await dispatch(
      recalculateAmortizationSchedule({
        id: facilityId ?? '',
        admin: facility?.accountManagementAdmin ?? ''
      })
    )) as any
    if (result.success) {
      setIsCalculationInSync(true)
      SuccessNotification({
        title: 'Successful Amortization Recalculate',
        message:
          'Amortization schedule is automaticly recalculated and now in sync with facility amount'
      })
      dispatch(loadSchedulerAmortizationByFacility(facilityId ?? ''))
      dispatch(loadAllFacilityAmendments())
    } else {
      ErrorNotification({
        title: 'Amortization Recalculate Failed',
        message:
          (result?.payload as string) || 'Amortization recalculate failed'
      })
    }
  }

  return (
    <>
      <Stack w="100%">
        <Breadcrumbs
          separator={<IonIcon name="chevron-forward-outline" />}
        >
          {items}
        </Breadcrumbs>
        {facility ? <FacilityHeader facility={facility} customTitle='Amortization Schedule' showStatusPill={false} /> : null}
        <Group w="100%" position="apart" className='tabsGapsActivity'>
          <Group position="right">
            <SegmentControlUpdated tabs={tabs} />
          </Group>
          <Group noWrap>
            {facility?.status === 'Draft' && deal && (
              <AmortizationScheduleHeaderActions facility={facility} deal={deal} isCalculationInSync={isCalculationInSync} primaryInput={true} recalculate={recalculate} />
            )}
          </Group>
        </Group>
        <Text className='sectionDetailHeader'>Amortization Details</Text>
        <Group position='apart' mt='12px' mb='12px'>
          <Group noWrap className='dataEntrySubSection'>
            <NewTextInput label='Frequency' readOnly value={
              frequencyOptions.find(
                option => option.value === amortizationSchedule?.frequency
              )?.label ?? ''
            } />
          </Group>
          <Group noWrap className='dataEntrySubSection'>
            <NewTextInput readOnly label='Payment Amount' value={formatNumberToCurrency(Number(amortizationSchedule?.amount ?? 0.0), facility?.baseCurrency ?? 'USD').toString()} />
          </Group>
          <Group noWrap className='dataEntrySubSection'>
            <NewTextInput readOnly label='Percentage' value={Number(amortizationSchedule?.percentage ?? 0.0).toString() + ' %'} />
          </Group>
          <Group noWrap className='dataEntrySubSection'>
            <NewTextInput readOnly label='First Payment Date' value={amortizationSchedule?.firstPaymentDate} />
          </Group>
        </Group>
        <Text className='sectionDetailHeader'>Schedule</Text>
        <Group w="100%" noWrap mt='12px'>
          <AmortizationScheduleDetails
            facilityId={facilityId}
            isCalculationInSync={isCalculationInSync}
            setIsCalculationInSync={setIsCalculationInSync}
            tab={tab}
          />
        </Group>
        <Group w="100%" noWrap mt='30px'>
          <NotesAndAttachment
            itemId={facility?.id ?? ''}
            type={ContractType.Facilities}
            itemAdmin={facility?.accountManagementAdmin ?? ''}
          />
        </Group>
      </Stack>
    </>
  )
}
