import React, { useState } from 'react'
import { Paper, Group, Badge, Button } from '@mantine/core'
import ItemName from '../../../common/item-name'
import { PortfolioParams } from 'app/models/portfolio-params'
import { useDispatch } from 'react-redux'
import { removePortfolios } from 'app/state/ducks/portfolios/thunks'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import DeleteConfirmation from 'app/views/components/delete-confirmation/DeleteConfirmation'

interface PortfolioItemProps {
    portfolio: PortfolioParams
    isExpanded: boolean
    onEdit: (item: PortfolioParams) => void
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({
    portfolio,
    isExpanded,
    onEdit,
}) => {
    const dispatch = useDispatch()

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false) // State to show delete modal

    const handleDeletePortfolio = async (portfolioId: string) => {
        const result: any = await dispatch(removePortfolios(portfolioId))
        if (!result.success) {
            ErrorNotification({ message: result.payload, title: 'Delete Portfolio Failed' })
            return
        }
        SuccessNotification({
            message: 'The portfolio has been successfully deleted.',
            title: 'Delete Successful',
        })
    }

    const confirmDelete = () => {
        handleDeletePortfolio(portfolio.id ?? '')
        setShowDeleteModal(false)
    }

    return (
        <>
            <Paper
                p="md"
                radius="md"
                className={`paper ${isExpanded ? 'paper-expanded' : ''}`}
            >
                <Group position="apart">
                    <Group spacing="sm">
                        <Badge className={`status-badge ${portfolio.isActive ? 'active' : 'inactive'}`}>
                            {portfolio.isActive ? 'ACTIVE' : 'INACTIVE'}
                        </Badge>
                        <ItemName name={portfolio.name} />
                    </Group>
                    <Group spacing="xs">
                        <Group spacing="xs">
                            <Badge className='portfolio-strategy-badge'>
                                {portfolio.code}
                            </Badge>
                            <Button onClick={() => onEdit(portfolio)} className="edit-button">
                                Edit
                            </Button>
                            <Button
                                onClick={() => setShowDeleteModal(true)} // Show confirmation modal
                                className="delete-button delete-button-pink"
                            >
                                Delete
                            </Button>
                        </Group>
                    </Group>
                </Group>
            </Paper>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <DeleteConfirmation
                    onConfirm={confirmDelete} // Delete when confirmed
                    onCancel={() => setShowDeleteModal(false)} // Close modal on cancel
                    message="Delete This Portfolio?" // Custom message
                />
            )}
        </>
    )
}

export default PortfolioItem
