import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getSubTrades,
  addSubTrade,
  editSubTrade,
  getSubTrade,
  deleteSubTrade,
  startLoadingSubTrades,
  stopLoadingSubTrades,
} from 'app/state/ducks/sub-trades/actions'

import { SubTradeParams } from 'app/models/sub-trade-params'

export const saveSubTrade =
  (savedSubTrade: SubTradeParams, subTrade?: any) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    
    if (subTrade) {
      return client
        .put(`${apiServerUrl}/api/sub-trades`, savedSubTrade)
        .then(subTrade => {
          dispatch(editSubTrade(subTrade.data))
          return { success: true, payload: subTrade.data }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    } else {
      return client
        .post(`${apiServerUrl}/api/sub-trades`, savedSubTrade)
        .then(subTrade => {
          dispatch(addSubTrade(subTrade.data))
          return { success: true, payload: subTrade.data }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }
  }

const loadOpenSubTrades = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/sub-trades`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

const loadSettledSubTrades = async () => {
  const apiServerUrl = config.backendUrl
  return await client
    .get(`${apiServerUrl}/api/locked-sub-trades`)
    .then(response => {
      return response.data
    })
    .catch(e => {
      console.log(e)
      return []
    })
}

export const loadAllSubTrades = () => async (dispatch: Dispatch) => {
  try {
    dispatch(startLoadingSubTrades())
    const [openSubTrade, settledSubTrades] = await Promise.all([
      loadOpenSubTrades(),
      loadSettledSubTrades(),
    ])

    const subDeals = settledSubTrades.map((a: { subTrade: any }) => ({
      ...a.subTrade,
      status: 'Settled',
    }))

    const allSubTrades = openSubTrade
      .map((subTrade: SubTradeParams) => ({ ...subTrade, status: 'Open' }))
      .concat(subDeals)
    return dispatch(getSubTrades(allSubTrades))
  } catch (error) {
    console.log('THUNK ERROR: ', error)
  } finally {
    dispatch(stopLoadingSubTrades())
  }
}


export const loadOneSubTrade =
  (subTradeId: string, status: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    const isTerminated = status.toLowerCase() === 'terminated'
    const subTradePath = isTerminated
      ? 'locked-sub-trades'
      : 'sub-trades'
    
    return client
      .get(`${apiServerUrl}/api/${subTradePath}/one?id=${subTradeId}`)
      .then(subTrades => {
        return dispatch(
          getSubTrade({
            ...subTrades.data.subTrade,
            status: subTradePath === 'locked-sub-trades' ? 'Settled' : 'Open',
          })
        )
      })
      .catch(e => {
        return { success: false, payload: e.response?.data?.error ?? 'Unknown error' }
      })
  }

export const setOneSubTrade = (subTrade: any) => async (dispatch: Dispatch) => {
  dispatch(getSubTrade({ ...subTrade }))
}

export const deleteSubTrades =
  (ids: string[]) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(`${apiServerUrl}/api/sub-trades?ids=${ids}`)
      .then(response => {
        return dispatch(deleteSubTrade(response.data))
      })
      .catch(e => {
        console.log('THUNK ERROR', e)
      })
  }